import React from 'react'
import './pageTitle.css'

function PageTitle(props ) {
    const menu = props.menu;
    const menuIndex = props.menuIndex;
    return (
        <div className='pagetitle'>
            <h1>
                {menu.items[menuIndex].label}
            </h1>
            <nav>
                <ol className='breadcrumb'>
                    <li className="breadcrumb-item">
                        <a href="/">
                            <i className="bi bi-house-door"></i>
                        </a>
                    </li>
                    
                    <li className="breadbrumb-item active"> 
                        {" / "+ menu.label + " / " + menu.items[menuIndex].label}
                    </li>
                </ol>
            </nav>
        </div>
    )
}

export default PageTitle

import React, { useEffect, useState } from "react";

import { useWhisper } from "@chengsokdara/use-whisper";
import "./chatInput.css";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
    chatMessageGet,
    chatMessageSend,
    chatMessageAdd,
    addMessages,
    chatMessageFile,
    selectChatRoomId,
    keywordGetList,
    selectKeyword,
} from "../../lib/features/chatMain/chatMainSlice";
import { 
    selectToken,
    selectUser,
 } from "../../lib/features/auth/authSlice";

const BE_HOST = process.env.REACT_APP_BE_HOST;

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";

function ChatInput() {
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState("");
    const roomId = useAppSelector(selectChatRoomId);
    const token = useAppSelector(selectToken);
    const user = useAppSelector(selectUser);
    const [selectedFile, setSelectedFile] = useState(null);
    const keywordList = useAppSelector(selectKeyword);
    const [filteredKeywords, setFilteredKeywords] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    // const ffmpeg = createFFmpeg({
    //     corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js',
    //     log: true,
    // });

    // const handleInputText = (value) => {
    //   setInputValue(value);
    // };

    const handleFileChange = (event) => {
        console.log('handleFileChange')
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files); // FileList를 상태로 설정
        } else {
            setSelectedFile(null); // 파일이 없을 경우 null로 설정
        }
    };

    //   const handleFileUpload = async () => {
    //     if (!selectedFile) return;

    //     const formData = new FormData();
    //     // formData.append(selectedFile);
    //     // FileList에서 각 파일을 FormData에 추가합니다.
    //     Array.from(selectedFile).forEach((file) => {
    //       formData.append(`files`, file); // 각 파일에 고유한 이름을 지정
    //     });

    //     // setTimeout(() => {
    //     await dispatch(
    //       chatMessageFile({
    //         room_id: roomId,
    //         formData: formData,
    //         message_type: "image_url",
    //         token: token,
    //       })
    //     );
    //     // ).then(() => {
    //     await dispatch(
    //       chatMessageGet({ room_id: roomId, token: token, offset: 0 })
    //     );
    //     setSelectedFile(null);
    //     //   });
    //     // }, 3000);
    //   };
    const handleFileUpload = async () => {
        console.log('handleFileUpload')
        if (!selectedFile) return;

        const formData = new FormData();
        let isValid = true;

        // 이미지 파일 MIME 타입 리스트
        const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        // FileList에서 각 파일을 확인
        Array.from(selectedFile).forEach((file) => {
            if (imageTypes.includes(file.type)) {
                formData.append('files', file); // 이미지 파일만 추가
            } else {
                isValid = false; // 이미지 파일이 아닌 경우
            }
        });

        if (!isValid) {
            alert('이미지 파일 형식만 업로드 가능합니다 (JPEG, JPG, PNG).'); // 에러 메시지 표시
            return; // 함수 종료
        }

        try {
            // 이미지 파일만 업로드
            await dispatch(
                chatMessageFile({
                    room_id: roomId,
                    formData: formData,
                    message_type: 'image_url',
                    token: token,
                })
            );

            // 메시지 목록 다시 불러오기
            await dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 }));
            setSelectedFile(null);
        } catch (error) {
            console.error('파일 업로드 중 오류 발생:', error);
        }
    };

    const handleSendMessage = async () => {
        if (!inputValue.trim()) return;

        const sendText = inputValue;
        setInputValue("");

        dispatch(chatMessageAdd(sendText));
        // console.log("send handleSendMessage fetch")
        const streamResponse = await fetch(BASE_URL + "/api/chatbot/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                room_id: roomId,
                message_text: sendText,
                message_type: "text",
            }),
        });

        const reader = streamResponse.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let jsonText = "";
        let string = "";
        let first = true;

        let bufferedData = "";
        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            bufferedData += decoder.decode(value, { stream: true });
            
            console.log(bufferedData)
            const parts = bufferedData.split('}\n{');

            for (let i = 0; i < parts.length; i++) {
                let completeJson;

                if (i === 0) {
                    completeJson = parts[i] + '}';
                } else if (i === parts.length - 1 && !done) {
                    bufferedData = '{' + parts[i];
                    break;
                } else {
                    completeJson = '{' + parts[i] + '}';
                }

                try {
                    const parmessages = JSON.parse(completeJson);
                    string += parmessages.text;

                    if (parmessages.extras) {
                        done = true;
                    }

                    bufferedData = "";

                    dispatch(
                        addMessages({
                            type: "basic",
                            isBot: true,
                            first: first,
                            message_text: string,
                            message_type: "text",
                            hasDoc: false,
                            hasImg: false,
                            sendTime: "",
                        })
                    );
                    first = false;

                } catch (error) {
                    console.log("Error parsing JSON:", error);
                }
            }
        }
        
        dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 }));

        // setTimeout(() => {
        //     dispatch(chatMessageSend({
        //         room_id: roomId,
        //         message_text: inputValue,
        //         message_type: "text",
        //         token: token
        //     })).then(() => {
        //         dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 }));
        //     });
        // }, 3000);

        // setInputValue('');
    };

    useEffect(() => {
        if (selectedFile) {
            handleFileUpload();
        }
    }, [selectedFile]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleInputText = (e) => {
        // if (e.nativeEvent.isComposing) {
        //     return;
        //   }

        const value = e.target.value
        dispatch(keywordGetList({ search: value, token }));
        setInputValue(value);
        console.log({keywordList})
        // if(!keywordList.keywords.length){return}

        if (value.length > 0) {
            const filtered = keywordList.keywords.filter((keyword) =>
                keyword.keyword_text.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredKeywords(filtered);
            setShowDropdown(filtered.length > 0);
        } else {
            setShowDropdown(false);
        }
    };

    const handleKeywordClick = (keyword) => {
        setInputValue(keyword);
        setShowDropdown(false);
    };
    return (
        <div class="input-field md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent w-full min-w-[400px]">
            <div>
                <div className="text-base px-3 m-auto w-full md:px-5 lg:px-1 xl:px-5">
                    <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                        <div className="relative w-full">

                            {showDropdown && (
                                <div className="absolute bottom-[50px] left-0 right-0 z-30 bg-white border border-gray-300 rounded-md shadow-md mb-2">
                                    {filteredKeywords&&filteredKeywords.map((keyword, index) => (
                                        <div
                                            key={index}
                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleKeywordClick(keyword.keyword_text)}
                                        >
                                            {keyword.keyword_text}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="group relative flex w-full items-center">
                                <div className="flex w-full flex-col gap-1.5 rounded-[26px] p-1.5 transition-colors bg-[#f4f4f4] dark:bg-token-main-surface-secondary">
                                    <div className="flex items-end gap-1.5 md:gap-2">
                                        <div className="relative">
                                            <span className="flex" data-state="closed">
                                                <span>
                                                    <input
                                                        multiple type="file"
                                                        tabIndex="-1"
                                                        id="file-upload"
                                                        className="hidden"
                                                        onChange={(e) => handleFileChange(e)}
                                                    />
                                                    <label htmlFor="file-upload"
                                                        className="flex items-center justify-center h-8 w-8 rounded-full text-token-text-primary dark:text-white focus-visible:outline-black dark:focus-visible:outline-white mb-1 ml-1.5"
                                                        aria-disabled="false"
                                                        aria-label="파일 첨부"
                                                        onClick={() => handleFileUpload()}
                                                    >

                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                                                                fill="currentColor"
                                                            ></path>
                                                        </svg>
                                                    </label>
                                                </span>
                                            </span>
                                            <div type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:ri:" data-state="closed"></div>
                                        </div>
                                        <div className="flex min-w-0 flex-1 flex-col">
                                            <textarea
                                                className="m-0 resize-none border-0 bg-transparent px-0 text-token-text-primary focus:ring-0 focus-visible:ring-0 max-h-52"
                                                rows="1"
                                                maxLength="1000"
                                                value={inputValue}
                                                onChange={(e) => handleInputText(e)}
                                                onKeyDown={handleKeyPress}
                                                placeholder={user.user_lang=="KR"? 
                                                    "질문을 입력해 주세요."
                                                    :"Please enter your question."}
                                                title={user.user_lang=="KR"? 
                                                    "질문을 입력해 주세요."
                                                    :"Please enter your question."}
                                            />
                                        </div>
                                        <button
                                            className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-black text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary disabled:bg-[#D7D7D7]"
                                            title="음성입력"
                                            data-toggle="modal"
                                            data-target="#addVoiceRecordModal"
                                        >
                                            <i className="bi bi-mic" />
                                        </button>
                                        <button
                                            aria-label="프롬프트 보내기"
                                            onClick={handleSendMessage}
                                            data-testid="send-button"
                                            className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-black text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary disabled:bg-[#D7D7D7]"
                                        >
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2xl">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-full px-2 py-2 text-center text-xs text-token-text-secondary empty:hidden md:px-[60px]">
                    <span> 
                        {user.user_lang=="KR"? 
                        "중요한 정보는 다시 확인하세요."
                        :"Please double-check important information."}
                    </span>
                </div>
            </div>
            {/* <div className="textarea-warp">
                <textarea
                    className="textarea"
                    rows="1"
                    maxLength="1000"
                    value={inputValue}
                    onChange={(e) => handleInputText(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="질문을 입력해 주세요."
                    title="질문을 입력해 주세요."
                />
            </div>
            <div className="btn-wrap">
                <div className="btn-plus" title="더보기">
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id="file-upload"
                        onChange={handleFileChange}
                        multiple
                    />
                    <label htmlFor="file-upload">
                        <i className="bi bi-plus" />
                    </label>
                </div>
                <div
                    className="btn-mic"
                    // onClick={handleClickRecording}
                    title="음성입력"
                    data-toggle="modal"
                    data-target="#addVoiceRecordModal"
                >
                    <i className="bi bi-mic" />
                </div>
                <div className="btn-send" title="전송" onClick={handleSendMessage}>
                    <i className="bi bi-send" />
                </div>
            </div> */}
        </div>
    );
    //   return (
    //     <div className="input-field">
    //       {/* 키워드 리스트 표시 */}
    //       {keywordList && keywordList.length > 0 && (
    //         <div className="keyword-list">
    //           {keywordList.map((keyword, index) => (
    //             <div
    //               key={index}
    //               className="keyword-item"
    //               onClick={() => handleKeywordClick(keyword.keyword_text)} // 클릭 핸들러 추가
    //             >
    //               {keyword.keyword_text}
    //             </div>
    //           ))}
    //         </div>
    //       )}
    //       <div className="textarea-warp">
    //         <textarea
    //           className="textarea"
    //           rows="1"
    //           maxLength="1000"
    //           value={inputValue}
    //           onChange={(e) => handleInputText(e.target.value)}
    //           onKeyDown={handleKeyPress}
    //           placeholder="질문을 입력해 주세요."
    //           title="질문을 입력해 주세요."
    //         />
    //       </div>
    //       <div className="btn-wrap">
    //         <div className="btn-plus" title="더보기">
    //           <input
    //             type="file"
    //             style={{ display: "none" }}
    //             id="file-upload"
    //             onChange={handleFileChange}
    //             multiple
    //           />
    //           <label htmlFor="file-upload">
    //             <i className="bi bi-plus" />
    //           </label>
    //         </div>
    //         <div
    //           className="btn-mic"
    //           // onClick={handleClickRecording}
    //           title="음성입력"
    //           data-toggle="modal"
    //           data-target="#addVoiceRecordModal"
    //         >
    //           <i className="bi bi-mic" />
    //         </div>
    //         <div className="btn-send" title="전송" onClick={handleSendMessage}>
    //           <i className="bi bi-send" />
    //         </div>
    //       </div>
    //     </div>
    //   );
}

export function VoiceRecordModal(props) {
    const dispatch = useAppDispatch();
    const roomId = useAppSelector(selectChatRoomId);
    const token = useAppSelector(selectToken);

    const { modalName } = props.modalName;
    const [isRecording, setIsRecording] = useState(false);
    const [recordingText, setRecordingText] = useState("");
    const [state, setState] = useState("init"); //"init"|"recording"|"modifying"|"send";

    const onTranscribe = async (blob) => {
        const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
        const body = JSON.stringify({ file: base64, model: "whisper-1" });
        const headers = { "Content-Type": "application/json" };
        const { default: axios } = await import("axios");
        const response = await axios.post("/api/whisper", body, {
            headers,
        });
        const { text } = await response.data;
        return {
            blob,
            text,
        };
    };

    const {
        recording,
        speaking,
        transcribing,
        transcript,
        pauseRecording,
        startRecording,
        stopRecording,
    } = useWhisper({
        response_format: "text", // output text instead of json
        temperature: 0.8, // random output
        whisperConfig: {
            language: "ko",
        },
        streaming: true,
        nonStop: false,
        // stopTimeout: 3000,
        apiKey: "sk-proj-pKvAzuvZCtB8R30jdJjJT3BlbkFJzla8FCZPrUraqboQBdLT", // YOUR_OPEN_AI_TOKEN
    });

    const handleClickRecording = () => {
        if (isRecording) {
            handlerStopRecording();
        } else {
            handlerStartRecording();
        }
    };

    const handlerStartRecording = () => {
        startRecording();
        setIsRecording(true);
        setState("recording");
    };

    const handlerStopRecording = () => {
        stopRecording();
        setIsRecording(false);
        setRecordingText(transcript.text);
        setState("modifying");
    };


    const handlerSendMessage = async () => {
        if (!recordingText.trim()) return;

        const sendText = recordingText;
        handlerStopRecording()
        setRecordingText("");

        dispatch(chatMessageAdd(sendText));
        // console.log("send handleSendMessage fetch")
        const streamResponse = await fetch(BASE_URL + "/api/chatbot/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                room_id: roomId,
                message_text: sendText,
                message_type: "text",
            }),
        });

        const reader = streamResponse.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let jsonText = "";
        let string = "";
        let first = true;

        let bufferedData = ""; 

        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            bufferedData += decoder.decode(value, { stream: true });
            
            console.log(bufferedData)
            const parts = bufferedData.split('}\n{');

            for (let i = 0; i < parts.length; i++) {
                let completeJson;

                if (i === 0) {
                    completeJson = parts[i] + '}';
                } else if (i === parts.length - 1 && !done) {
                    bufferedData = '{' + parts[i];
                    break;
                } else {
                    completeJson = '{' + parts[i] + '}';
                }

                try {
                    const parmessages = JSON.parse(completeJson);
                    string += parmessages.text;

                    if (parmessages.extras) {
                        done = true;
                    }

                    bufferedData = "";

                    dispatch(
                        addMessages({
                            type: "basic",
                            isBot: true,
                            first: first,
                            message_text: string,
                            message_type: "text",
                            hasDoc: false,
                            hasImg: false,
                            sendTime: "",
                        })
                    );
                    first = false;

                } catch (error) {
                    console.log("Error parsing JSON:", error);
                }
            }
        }
        
        dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 }));
        try{
            document.getElementsByClassName("modal fade show")[0].className =
                "modal fade";
            document.getElementsByClassName("modal-backdrop fade show")[0].className =
                "modal-backdrop fade";
        }catch{

        }
    };

    return (
        <div
            className="modal fade"
            id="addVoiceRecordModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addVoiceRecordModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addVoiceRecordModalLabel"></h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="recording-info">
                            {/* <p><strong>Recording:</strong> {recording ? "녹음 중" : "대기 중"}</p>
                            <p><strong>Speaking:</strong> {speaking ? "말하는 중" : "대기 중"}</p>
                            <p><strong>Transcribing:</strong> {transcribing ? "변환 중" : "대기 중"}</p> */}
                            <p>
                                <strong>Transcribed Text:</strong> {transcript.text}
                            </p>

                            <div
                                className={`recording-animation ${isRecording ? "active" : ""}`}
                            ></div>
                            <button
                                className={`btn ${isRecording ? "btn-danger" : "btn-primary"}`}
                                onClick={handleClickRecording}
                            >
                                {isRecording ? "Stop Recording" : "Start Recording"}
                            </button>
                        </div>
                        <form className="form-group">
                            {/* <label htmlFor="RecordingText">음성 입력</label> */}
                            {/* <p><strong>Transcribed Text:</strong> {transcript.text }</p> */}

                            {state == "modifying" ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    id="RecordingText"
                                    onChange={(e) => {
                                        setRecordingText(e.target.value);
                                    }}
                                    value={recordingText}
                                />
                            ) : (
                                <></>
                            )}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handlerSendMessage}
                        >
                            Send message
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatInput;

import React, { useEffect, useState } from "react";
import {
  setDocViewer,
  setToggleDocViewer,
  meesageLike,
  messageFeedbackText,
  selectDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import "./MessageBasic.css";
import { debounce } from "lodash";
import Popover from "./Popover";
import { selectToken } from "../../lib/features/auth/authSlice";
import ReactDOMServer from "react-dom/server";
function MessageBasic(props) {
  const { type, isBot, text, sendTime, _id, feedback, evidence } = props.model;
  const roomId = props.roomId;
  const navigate = useNavigate();
  const [likeState, setLikeState] = useState({ like: false, dislike: false });
  const [review, setReview] = useState("");
  const [isReview, setIsReview] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const token = useAppSelector(selectToken);
  const docViewer = useAppSelector(selectDocViewer);
  const dispatch = useAppDispatch();

  const [sentenceFeedback, setSentenceFeedback] = useState(
    text.split(".").map(() => ({ like: false, dislike: false }))
  );
  const handleClickDocView = debounce((url, page) => {
    dispatch(
      setDocViewer({
        docUrl: url,
        docPage: page,
        isOn: docViewer.isOn,
      })
    );

    if (!docViewer.isOn) {
      document.body.classList.toggle("toggle-sidebar-doc");
      dispatch(setToggleDocViewer());
    }
  }, 1000);

  // useEffect(() => {
  //   if (feedback) {
  //     const updatedFeedback = text.split(".").map((_, idx) => ({
  //       like: feedback.is_like === 1,
  //       dislike: feedback.is_dislike === 1,
  //     }));
  //     setSentenceFeedback(updatedFeedback);
  //   }
  // }, [feedback, text]);

  // useEffect(() => {
  //   if (feedback) {
  //     setLikeState({
  //       like: feedback.is_like === 1,
  //       dislike: feedback.is_dislike === 1,
  //     });

  //     if (feedback.feedback_text) {
  //       setReviewSubmitted(true);
  //       // setIsReview(true);
  //       setReview(feedback.feedback_text);
  //     } else {
  //       setReviewSubmitted(false); // 상태 초기화
  //     }
  //   } else {
  //     setLikeState({ like: false, dislike: false }); // 상태 초기화
  //     setReviewSubmitted(false); // 상태 초기화
  //   }
  // }, [feedback]);

  const handleLike = (sentenceIndex, data, documents) => {
    setSentenceFeedback((prevState) => {
      const updatedState = [...prevState];
      updatedState[sentenceIndex] = { like: true, dislike: false };
      return updatedState;
    });

    dispatch(
      meesageLike({
        like: 1,
        token,
        roomId,
        documents,
        rated_text: data,
      })
    );
  };

  const handleEmailSend = () => {
    navigate(`/service/${roomId}`);
    // roomId
  };

  const handleDislike = (sentenceIndex, data, documents) => {
    setSentenceFeedback((prevState) => {
      const updatedState = [...prevState];
      updatedState[sentenceIndex] = { like: false, dislike: true };
      return updatedState;
    });

    dispatch(
      meesageLike({
        like: 0,
        token,
        roomId,
        documents,
        rated_text: data,
      })
    );
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleTextReview = () => {
    setIsReview(!isReview);
  };

  const handleReviewSubmit = () => {
    if (review.trim() !== "") {
      dispatch(
        messageFeedbackText({
          message_id: _id,
          token: token,
          feedback_text: review,
        })
      );
      setReviewSubmitted(true);
      setIsReview(!isReview);
    }
  };

  let item_ref = <></>;
  if (evidence) {
    item_ref = evidence.map((evi, idx) => (
      <Popover
        content={evi.evidence_text.replace(/(\n|\r\n)/g, "<br>")}
        handleDocView={() => handleClickDocView(evi.pdf_url, evi.pdf_page)}
      >
        {"[ref-" + idx + "]"}
      </Popover>
    ));

    // console.log(item_ref)
  }
  // console.log(text_new)

  //.replace(/\[ref-(\d+)\]/g, '<a href="#ref-$1">[ref-$1]</a>').replace(/(\n|\r\n)/g, '<br>')
  //   console.log("split")
  //   console.log(text
  //     .replace(/(\n|\r\n)/g, "<br>")
  //     .split(/\[ref-(\d+)\]/g))
  var list_ref = text.replace(/(\n|\r\n)/g, "<br>").split(/\[ref-(\d+)\]/g);
  var regex = /^[0-9]+$/;

  function organizeContent(arr) {
    let result = []; // 최종 결과 저장
    let currentContent = null; // 현재 처리 중인 컨텐츠
    let currentNumbers = []; // 현재 컨텐츠에 할당될 숫자들

    arr.forEach((item) => {
      if (item === "") {
        // 빈 문자열은 무시
        return;
      }

      if (!isNaN(item) && item.length <= 2) {
        // 숫자이고 2자리 이하일 때
        currentNumbers.push(item);
      } else {
        // 새로운 컨텐츠가 나오면 이전 컨텐츠와 숫자를 저장
        if (currentContent) {
          result.push([
            currentContent,
            currentNumbers.length > 0 ? currentNumbers : null,
          ]);
        }
        // 새 컨텐츠로 갱신
        currentContent = item;
        currentNumbers = []; // 숫자 배열 초기화
      }
    });

    // 마지막 컨텐츠 처리
    if (currentContent) {
      result.push([
        currentContent,
        currentNumbers.length > 0 ? currentNumbers : null,
      ]);
    }

    return result;
  }

  var display_text = organizeContent(list_ref);

  //   console.log(ReactDOMServer.renderToString(item_ref[0]))
  // console.log({evidence,display_text})
  return (
    <div
      className={
        isBot
          ? "massageBox-bot flex max-w-full flex-col flex-grow mb-10"
          : "massageBox-me flex max-w-full flex-col flex-grow mb-10"
      }
    >
      <div className={isBot ? "massageBody-bot" : "massageBody-me"}>
        {isBot ? (
          <div className="messageText">
            {display_text &&
              display_text.map((data, idx) => {
                
                const documents = data[1]
                  ?data[1]
                  .map((index) => {
                    if (evidence && evidence[index]) {
                      const doc = { ...evidence[index] }; // doc의 복사본 생성
                      
                      doc.pdf_page = JSON.parse(doc.pdf_page)
                      // pdf_page가 배열 형태로 왔을 때 처리
                      // if (doc.pdf_page && Array.isArray(doc.pdf_page)) {
                      //   // 첫 번째 요소에서 "[" 제거, 마지막 요소에서 "]" 제거
                      //   doc.pdf_page[0] = doc.pdf_page[0].replace('[', '').trim();
                      //   doc.pdf_page[doc.pdf_page.length - 1] = doc.pdf_page[doc.pdf_page.length - 1].replace(']', '').trim();
                        
                      //   // 정상적인 리스트로 변환
                      //   doc.pdf_page = doc.pdf_page.map(page => page.trim());
                      // }
                      // console.log(doc)
        
                      return doc;
                    }
                    return null;
                  })
                  .filter((doc) => doc !== null) // null 값을 필터링
              : [];

                const sentence = data[0].replace(/(\n|\r\n)/g, "<br>");

                return (
                  <PopoverMessage
                    key={idx} 
                    data={sentence}
                    documents={documents}
                    handleViewer={handleClickDocView}
                    handleLike={() => handleLike(idx, sentence, documents)}
                    handleDislike={() => handleDislike(idx, sentence, documents)}
                    likeState={sentenceFeedback[idx]}
                  />
                );
              })}

          </div>
        ) : (
          <div className="messageText">{text}</div>
        )}

        {/* <div
          className="messageText"
          dangerouslySetInnerHTML={{
            __html: text
              .replace(/(\n|\r\n)/g, "<br>")
              .replace(/\[ref-(\d+)\]/g, ReactDOMServer.renderToStaticMarkup(item_ref[0])),
          }}
        >
        </div> */}
        {/* <div className="messageViewDoc">
          {item_ref}
        </div> */}
      </div>
    </div>
  );
}

const PopoverMessage = ({
  data,
  documents,
  handleViewer,
  handleLike,
  handleDislike,
  likeState,
}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // 기존 타이머 제거
    }
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setPopoverVisible(false);
      setDropdownVisible(false);
    }, 200); // 200ms 지연 후 숨기기
    setTimeoutId(id);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  // console.log({documents})
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative inline-block w-full"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: data,
        }}
        className="p-2 bg-white "
      ></div>

      {/* Popover */}
      {isPopoverVisible && (
        <div className="relative  ">
          <div class="absolute bg-gray-100 top-0 right-0 -translate-y-full items-center justify-start rounded-xl p-1 z-10 mt-1 bg-token-main-surface-primary md:border md:border-token-border-light flex">
            <div class="flex items-center">
              <span
                class=""
                data-state="closed"
                data-aria-hidden="true"
                aria-hidden="true"
              >
                <button
                  class="rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary"
                  aria-label="복사"
                >
                  <span class="flex h-[30px] w-[30px] items-center justify-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon-md-heavy"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 5C7 3.34315 8.34315 2 10 2H19C20.6569 2 22 3.34315 22 5V14C22 15.6569 20.6569 17 19 17H17V19C17 20.6569 15.6569 22 14 22H5C3.34315 22 2 20.6569 2 19V10C2 8.34315 3.34315 7 5 7H7V5ZM9 7H14C15.6569 7 17 8.34315 17 10V15H19C19.5523 15 20 14.5523 20 14V5C20 4.44772 19.5523 4 19 4H10C9.44772 4 9 4.44772 9 5V7ZM5 9C4.44772 9 4 9.44772 4 10V19C4 19.5523 4.44772 20 5 20H14C14.5523 20 15 19.5523 15 19V10C15 9.44772 14.5523 9 14 9H5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </button>
              </span>
              <div class="flex" data-aria-hidden="true" aria-hidden="true">
                <span class="" data-state="closed">
                  <button
                    onClick={handleLike}
                    className={`rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary ${
                      likeState&&likeState.like ? "bg-blue-500 text-white" : ""
                    }`} // likeState.like이 true일 때 색상 변경
                    aria-label="좋은 응답"
                  >
                    <span class="flex h-[30px] w-[30px] items-center justify-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-md-heavy"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </span>
                <span class="" data-state="closed">
                  <button
                    onClick={handleDislike}
                    className={`rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary ${
                      likeState&&likeState.dislike ? "bg-red-500 text-white" : ""
                    }`}
                    aria-label="별로인 응답"
                  >
                    <span class="flex h-[30px] w-[30px] items-center justify-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-md-heavy"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </span>
                {documents && documents[0] !== undefined ? (
                  <span class="" data-state="closed">
                    <button
                      onClick={toggleDropdown}
                      class="rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary"
                      aria-label="문서 목록 보기"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h6m2 8H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                        />
                      </svg>
                    </button>
                  </span>
                ) : (
                  <span class="" data-state="closed">
                    <button
                      // onClick={toggleDropdown}
                      class="rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary"
                      aria-label="문서 목록 보기"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h6m2 8H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                        />
                      </svg>
                    </button>
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* 드롭다운 문서 목록 */}
          {isDropdownVisible && (
            <div className="absolute right-0 mt-1 w-56 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
              <ul className="py-0">
              {documents &&
                documents.map((doc, index) => {
                  let pdfPages = Array.isArray(doc.pdf_page) ? doc.pdf_page : [doc.pdf_page];
                  
                  // console.log({pdf_page: pdfPages});

                  return pdfPages && pdfPages.map((p, pidx) => (
                    <li
                      key={`${index}-${pidx}`}
                      className="px-2 py-1 hover:bg-gray-100"
                      onClick={() => handleViewer(doc.pdf_url, p)}
                    >
                      <span className="font-semibold">
                        {doc.pdf_id + "번 pdf - " + "page " + p}
                      </span>
                    </li>
                  ));
                })
              }
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBasic;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  top : 0px;
  position: absolute;
  margin-top: 30px;
  padding: 20px 30px;
  transition: all 0.3s;
  align-items: start;
  /* width: 80vw; */
  min-width: 1000px;
}

.contentPanel {
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px 5px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}

table th,
table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
}
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

TreeNode {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,gCAAgC;AAClC;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".dashboard {\n  top : 0px;\n  position: absolute;\n  margin-top: 30px;\n  padding: 20px 30px;\n  transition: all 0.3s;\n  align-items: start;\n  /* width: 80vw; */\n  min-width: 1000px;\n}\n\n.contentPanel {\n  background-color: white;\n  border-radius: 10px;\n  padding: 20px 20px 5px 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 5px;\n}\n\ntable th,\ntable td {\n  padding: 5px;\n  text-align: left;\n  border-bottom: 1px solid #e5e5e5;\n}\n.pagination {\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  list-style: none;\n}\n\nTreeNode {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

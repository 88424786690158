// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .chatHeader{
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 10px rgba(1, 41, 112, 0.1);
    background-color: #efefef;
    padding-left: 20px;
    font-weight: 600;
    color: #040404;
} */
.chatHeader {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: var(--padding-default);
    background-color: var(--bg-color-secondary);
    font-weight: 600;
    font-size: var(--font-size-base);
    color: var(--text-color-primary);
    /* border-bottom: 1px solid var(--text-color-secondary); */
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.chatHeader .toggle-sidebar-doc-btn{
    font-size: 20px;
    padding-left: 30px;
    cursor: pointer;
    /* color: #014970; */
}`, "",{"version":3,"sources":["webpack://./src/components/main/chatHeader.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;AACH;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,oCAAoC;IACpC,2CAA2C;IAC3C,gBAAgB;IAChB,gCAAgC;IAChC,gCAAgC;IAChC,0DAA0D;IAC1D,0CAA0C;AAC9C;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":["/* .chatHeader{\n    transition: all 0.5s;\n    z-index: 997;\n    height: 60px;\n    box-shadow: 0px 2px 10px rgba(1, 41, 112, 0.1);\n    background-color: #efefef;\n    padding-left: 20px;\n    font-weight: 600;\n    color: #040404;\n} */\n.chatHeader {\n    display: flex;\n    align-items: center;\n    height: 60px;\n    padding-left: var(--padding-default);\n    background-color: var(--bg-color-secondary);\n    font-weight: 600;\n    font-size: var(--font-size-base);\n    color: var(--text-color-primary);\n    /* border-bottom: 1px solid var(--text-color-secondary); */\n    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);\n}\n\n.chatHeader .toggle-sidebar-doc-btn{\n    font-size: 20px;\n    padding-left: 30px;\n    cursor: pointer;\n    /* color: #014970; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { setChatMode, setChatRoomId } from '../lib/features/chatMain/chatMainSlice';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useNavigate } from 'react-router-dom';

const MenuPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chatMode = useAppSelector((state) => state.chatMain.chatMode);

  const [selectedMenu1, setSelectedMenu1] = useState(null);

  const handleMenu1Click = (menu) => {
    setSelectedMenu1(selectedMenu1 === menu ? null : menu); // 클릭 시 세부 메뉴를 토글
    dispatch(setChatMode({
      mode_1: menu,
      mode_2: "",
    }));
  };

  const handleMenu2Click = (menu) => {
    dispatch(setChatMode({
      mode_1: chatMode.mode_1,
      mode_2: menu,
    }));
    dispatch(setChatRoomId(null));
    if(chatMode.mode_1=="BWMS"&&menu=='3'){
      navigate('/service');  
      return 
    }
    navigate('/chat');
  };

  return (
    <div className="relative flex flex-col w-full min-h-screen bg-gradient-to-b from-blue-900 to-indigo-800">
      {/* 최상단 문구 */}
      <header className="text-center py-12">
        <h1 className="text-5xl font-extrabold text-white mb-4">Empowering Your Tomorrow</h1>
        <h2 className="text-2xl font-semibold text-gray-300 mb-4">Building Technology with Trust</h2>
        <p className="text-lg text-gray-200">
          에스엔시스는 기술을 통해 오늘의 신뢰를 내일로 이어갑니다.
        </p>
      </header>

      {/* 세로로 배치된 메뉴 버튼 */}
      <div className="flex flex-col w-full items-center flex-grow">
        <FullScreenButton
          text="BWMS"
          description="Ballast Water Management Systems"
          onClick={() => handleMenu1Click('BWMS')}
          active={selectedMenu1 === 'BWMS'}
          selected={selectedMenu1}
        />
        {selectedMenu1 === 'BWMS' && (
          <div className="w-full flex flex-col items-center bg-gray-100 p-2 h-[20vh] ">
            <OptionButton onClick={() => handleMenu2Click('1')} text="Trouble Shooting" />
            <OptionButton onClick={() => handleMenu2Click('2')} text="Maintenance & Operation" />
            <OptionButton onClick={() => handleMenu2Click('3')} text="General Inquiry" />
          </div>
        )}

        <FullScreenButton
          text="AUTOMATION"
          description="Advanced Industrial Automation"
          onClick={() => handleMenu1Click('AUTOMATION')}
          active={selectedMenu1 === 'AUTOMATION'}
          selected={selectedMenu1}
        />
        {selectedMenu1 === 'AUTOMATION' && (
          <div className="w-full flex flex-col items-center bg-gray-100 p-2">
            <OptionButton onClick={() => handleMenu2Click('1')} text="AUTOMATION 1" />
            <OptionButton onClick={() => handleMenu2Click('2')} text="AUTOMATION 2" />
            <OptionButton onClick={() => handleMenu2Click('3')} text="AUTOMATION 3" />
          </div>
        )}

        <FullScreenButton
          text="SWITCH"
          description="Electrical Switch Solutions"
          onClick={() => handleMenu1Click('SWITCH')}
          active={selectedMenu1 === 'SWITCH'}
          selected={selectedMenu1}
        />
        {selectedMenu1 === 'SWITCH' && (
          <div className="w-full flex flex-col items-center bg-gray-100 p-2">
            <OptionButton onClick={() => handleMenu2Click('1')} text="SWITCH 1" />
            <OptionButton onClick={() => handleMenu2Click('2')} text="SWITCH 2" />
            <OptionButton onClick={() => handleMenu2Click('3')} text="SWITCH 3" />
          </div>
        )}
      </div>
    </div>
  );
};

// 풀스크린 버튼 컴포넌트 (세로로 화면을 채우는 디자인, 애니메이션 적용)
const FullScreenButton = ({ text, description, onClick, active, selected }) => {
  const scaleClass = active ? 'h-[10vh] bg-white' : selected ? 'h-[20vh] bg-gray-700' : 'h-[10vh] bg-gray-300';

  return (
    <div
      onClick={onClick}
      className={`flex flex-col justify-center items-center w-full flex-grow text-gray-800 p-2 tansition-all duration-300 cursor-pointer border-t border-gray-300 transform ${scaleClass} `}
    >
      <h3 className="text-3xl font-bold">{text}</h3>
      <p className="text-lg mt-2">{description}</p>
    </div>
  );
};

// 세부 옵션 버튼 컴포넌트
const OptionButton = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center justify-center w-full max-w-screen-md h-12 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-400 transition-transform duration-300 cursor-pointer transform hover:scale-105 mt-2"
    >
      <span className="text-sm font-semibold">{text}</span>
    </div>
  );
};

export default MenuPage;

import React from "react";
import {
  setDocViewer,
  selectDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";

function MessageImage(props) {
  const { type, isBot, imageUrl, text, sendTime } = props.model;
  const dispatch = useAppDispatch();
  // console.log(imageUrl);
  const handleClickDocView = (url, page) => {
    dispatch(
      setDocViewer({
        docUrl: url,
        docPage: page,
      })
    );
  };

  return (
    <div className={isBot ? "massageBox-bot" : "massageBox-me"}>
      <div className={isBot ? "massageBody-bot" : "massageBody-me"}>
        {/* <div
          className="messageText"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div> */}
        <div>
          <img className="messageImage" src={imageUrl} />
        </div>
        
      </div>
      <div className="sendTime">{sendTime}</div>
    </div>
  );
}

export default MessageImage;

import { authLogin, User, authRegister, UserLangChangeData } from "./authSlice";

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BE_HOST = process.env.REACT_APP_BE_HOST;
// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
// const BASE_URL: string = "http://localhost:12345";
// A mock function to mimic making an async request for data

export const fetchUser = async (token: string) => {
  try {
    const response = await fetch(BASE_URL + "/api/user/me", {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    const result: any = await response.json();

    return result;
  } catch (error) {
    console.error("Error in fetchUser:", error);
    throw error;
  }
};

export const fetchUserLangChange = async (userLangChangeData: UserLangChangeData) => {
  try {
    const response = await fetch(BASE_URL + "/api/user/lang?lang="+userLangChangeData.lang, {
      method: "PATCH",
      headers: { "Content-Type": "application/json", Authorization: userLangChangeData.token },
    });

    const result: any = await response.json();

    return result;
  } catch (error) {
    console.error("Error in fetchUser:", error);
    throw error;
  }
};

export const fetchLogin = async (authData: authLogin) => {
  const response = await fetch(BASE_URL + "/api/auth/signin", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: authData.useremail,
      password: authData.password,
    }),
  });
  const result: any = await response.json();
  // console.log(result);
  return result;
};

export const fetchLogout = async (token: string) => {
  const response = await fetch(BASE_URL + "/api/auth/del", {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: token },
  });
  const result: User = await response.json();

  return result;
};

export const fetchRegister = async (authData: authRegister) => {
  const response = await fetch(BASE_URL + "/api/auth/signup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: authData.useremail,
      user_nm: authData.username,
      password: authData.password,
      lang: authData.lang,
    }),
  });
  const result: any = await response.json();

  return result;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    line-height: 1;
}

@media (min-width: 1200px) {
    .logo{
        width: 280;
    }
}

.logo img{
    max-height: 26px;
    margin-right: 6px;
}

.logo span{
    font-size: 26px;
    font-weight: 700;
    color: #012970;
    font-family: 'Nunito', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/logo.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,iCAAiC;AACrC","sourcesContent":[".logo {\n    line-height: 1;\n}\n\n@media (min-width: 1200px) {\n    .logo{\n        width: 280;\n    }\n}\n\n.logo img{\n    max-height: 26px;\n    margin-right: 6px;\n}\n\n.logo span{\n    font-size: 26px;\n    font-weight: 700;\n    color: #012970;\n    font-family: 'Nunito', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
}

.header .toggle-sidebar-btn{
    font-size: 32px;
    padding-left: 30px;
    cursor: pointer;
    color: #014970;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/header.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,8CAA8C;IAC9C,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".header{\n    transition: all 0.5s;\n    z-index: 997;\n    height: 60px;\n    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);\n    background-color: #fff;\n    padding-left: 20px;\n}\n\n.header .toggle-sidebar-btn{\n    font-size: 32px;\n    padding-left: 30px;\n    cursor: pointer;\n    color: #014970;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

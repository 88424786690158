import React, { useEffect, useRef, useState } from "react";
import "./chatContents.css";
import Message from "./Message";

import {
  chatMessageGet,
  chatMessageAdd,
  selectChatMain,
} from "../../lib/features/chatMain/chatMainSlice";
import {
    selectUser,
  } from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";

function ChatContents() {
  const messageEndRef = useRef(null);

  const chatMain = useAppSelector(selectChatMain);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    scrollToBottom();
    // console.log("call")
  }, [chatMain.messages]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  };

  const moveServicePage = () => {
    const roomId = chatMain.roomId;
    navigate("/service/" + roomId);
  };

  return (
    <div className="chatContentsContainer flex react-scroll-to-bottom--css-bgack-79elbk h-full">
      <div
        className="chatContents text-base py-[18px] px-3 m-auto w-full h-full md:px-5 lg:px-1 xl:px-5"
        ref={messageEndRef}
      >
        <div className="group/conversation-turn relative flex w-full h-full min-w-0 flex-col agent-turn justify-between">
          <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn">
            <div className="chatMargin"></div>
            {chatMain.messages &&
              chatMain.messages.map((item, index) => (
                <Message key={index} model={item} roomId={chatMain.roomId} />
              ))}

            <div className="chatFootMargin" />
          </div>
          <a
            onClick={() => moveServicePage()}
            class="relative w-full px-2 my-3  text-right text-xs text-gray-600 text-token-text-secondary empty:hidden md:px-[60px]"
          >
            {user.user_lang=="KR"? "그래도 문제가 해결되지 않으신가요? 서비스를 신청하세요. 바로가기 >>"
            :"Still not solving the problem? Apply for service. Go to >>"}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChatContents;

import React from "react";
import "./dashboard.css";
import AdminChatbotPage from "./pages/AdminChatbotPage";
import AdminLogPage from "./pages/AdminLogPage";
import AdminUserPage from "./pages/AdminUserPage";
import AdminModelPage from "./pages/AdminModelPage";
import AdminFaqPage from "./pages/AdminFaqPage";
function Dashboard(props) {
  const menu = props.menu;
  const menuIndex = props.menuIndex;

  return (
    <section
      className="dashboard section"
      style={{
        height: "90vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        scrollBehavior: "smooth",
      }}
    >
      {menu.label == "관리" ? (
        <>
          {menu.items[menuIndex].label ==  "사용자/그룹" ? <AdminUserPage /> : null}
          {menu.items[menuIndex].label == "챗봇/파일" ? <AdminChatbotPage /> : null}
          {/* {menu.items[menuIndex].label == 2 ? <AdminModelPage /> : null} */}
          {menu.items[menuIndex].label == "로그/사용 현황" ? <AdminLogPage /> : null}
          {menu.items[menuIndex].label == "FAQ&Rating" ? <AdminFaqPage /> : null}
        </>
      ) : null}
      {/* {" / "+ menu.label + " / " + menu.items[menuIndex].label} */}

      <div className="row">
        <div className="col-lg-8">
          <div className="row"></div>
        </div>
        <div className="col-lg-4"></div>
      </div>
    </section>
  );
}

export default Dashboard;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CountrySelect from "react-flags-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import {
  selectChatMain,
  chatMessageGet,
} from "../lib/features/chatMain/chatMainSlice";

import {
  selectUser
} from "../lib/features/auth/authSlice"
import { selectToken } from "../lib/features/auth/authSlice";
import Header from "../components/dashboard/Header";

const EmailSendPage = () => {
  const { roomId } = useParams();
  const chatMain = useAppSelector(selectChatMain);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);

  const [formData, setFormData] = useState({
    yourName: user.user_name,
    vesselName: "",
    serviceCountry: null,
    serviceDate: new Date(),
    issueDetail: "",
    attachment: null,
  });
  const [errorMessage, setErrorMessage] = useState(""); // 에러 메시지 상태 추가
  const [successMessage, setSuccessMessage] = useState(""); // 성공 메시지 상태 추가
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    chatMessageGet({ room_id: roomId, token: token, offset: 0 });
  }, [roomId]); // 의존성 배열에 roomId 추가

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, serviceCountry: selectedOption });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, serviceDate: date });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // 유효성 검사
    if (
      !formData.yourName ||
      !formData.vesselName ||
      !formData.serviceCountry ||
      !formData.issueDetail
    ) {
      setErrorMessage("모든 필수 필드를 입력하세요.");
      setSuccessMessage(""); // 성공 메시지가 있다면 초기화
      return;
    }

    // 유효성 통과 후 데이터 제출 및 폼 초기화
    console.log("Form Data:", formData);
    console.log("Room ID:", roomId);

    setFormData({
      yourName: "",
      vesselName: "",
      serviceCountry: null,
      serviceDate: new Date(),
      issueDetail: "",
      attachment: null,
    });
    setErrorMessage(""); // 에러 메시지 초기화
    setSuccessMessage("성공적으로 전송되었습니다!"); // 성공 메시지 설정
  };

  return (
    <div className="flex h-full w-full overflow-hidden transition-colors">
      <main className="flex-1 flex flex-col justify-center items-center w-full bg-gray-100">
        <aside className="composer-parent flex  w-full h-full focus-visible:outline-0">
          <Header isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          <div className="container mx-auto flex flex-col lg:flex-row justify-between max-w-5xl mt-12 p-8 bg-white shadow-lg rounded-lg">
            <form onSubmit={handleSubmit} className="flex-1 mb-8 md:mb-0 px-2">
              <h2 className="text-2xl font-semibold text-blue-600 mb-6">Send Email</h2>
              {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>} {/* 에러 메시지 출력 */}
              {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>} {/* 성공 메시지 출력 */}

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Your Name</label>
                <input
                  type="text"
                  name="yourName"
                  value={formData.yourName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Vessel Name</label>
                <input
                  type="text"
                  name="vesselName"
                  value={formData.vesselName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Service Country</label>
                <CountrySelect
                  selected={formData.serviceCountry}
                  onSelect={handleCountryChange}
                  showFlags={true}
                  showCountryName={true}
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Service Request Date</label>
                <DatePicker
                  selected={formData.serviceDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy/MM/dd"
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Issue Detail</label>
                <textarea
                  name="issueDetail"
                  value={formData.issueDetail}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500 resize-none"
                  rows="5"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Attachment</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Send Email
              </button>
            </form>

            <div className="chat-history flex-1 md:pl-8 border-t md:border-t-0 md:border-l border-gray-300 overflow-scroll overflow-x-hidden">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Chat History</h3>
              {chatMain?.messages?.length > 0 ? (
                chatMain.messages.map((message, index) => (
                  <div
                    key={message.id}
                    className={`mb-4 p-4 rounded-lg ${message.isBot ? "bg-blue-100" : "bg-gray-100"
                      }`}
                  >
                    <p className="font-semibold">{message.isBot ? "Support" : "User"}: {message.text}</p>
                    {message.evidence?.length > 0 && (
                      <div className="mt-2">
                        <strong>Evidence:</strong>
                        {message.evidence.map((evi, eviIndex) => (
                          <div key={evi.evidence_id}>
                            <p>Text: {evi.evidence_text}</p>
                            <a href={evi.pdf_url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                              View PDF (Page {evi.pdf_page})
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No messages yet.</p>
              )}
            </div>

          </div>
        </aside>
      </main>
    </div>
  );
};

export default EmailSendPage;

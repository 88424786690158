import React from 'react'
import './chatHeader.css'

import {
  setDocViewer,
  setToggleDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import {useAppDispatch, useAppSelector } from "../../lib/hooks";


function ChatHeader({title}) {
  const dispatch = useAppDispatch();
  const handleToggleDocSideBar = () => {
      document.body.classList.toggle('toggle-sidebar-doc');
      dispatch(setToggleDocViewer());
  };

  return (
    <div 
        className='chatHeader top-0 d-flex align-items-center justify-content-between p-3'>
            <span>{title}</span> 
            <i
              className="bi bi-file-earmark-richtext toggle-sidebar-doc-btn"
              onClick={handleToggleDocSideBar}
          >View PDF</i>
    </div>
  )
}

export default ChatHeader

import React from 'react'
// import './main.css';
import PageTitle from './PageTitle';
import Dashboard from './Dashboard';

import {
  selectIndex,
  selectSideMenu,
} from "../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import Header from './Header';

function Main({isOpen, setIsOpen}) {
  const dispatch = useAppDispatch();
  const selIndex = useAppSelector(selectIndex)
  const sideMenu = useAppSelector(selectSideMenu)

  // console.log("main")
  // console.log(selIndex)
  // console.log(sideMenu)
  return (
    <main id="main" 
      // className={`chatMain relative h-full w-full flex-1 overflow-auto transition-width transition-all duration-300 ease-in-out ${!isOpen ? 'ml-[-260px]' : 'ml-0'}`}>
      className='flex-1 flex flex-col justify-start bg-gray-100'>
        <aside className="composer-parent flex h-full focus-visible:outline-0">
          {/* <PageTitle menu={sideMenu[selIndex[0]]} menuIndex={selIndex[1]}/> */}
          <Header isOpen={isOpen} setIsOpen={setIsOpen}/>
          <Dashboard menu={sideMenu[selIndex[0]]} menuIndex={selIndex[1]}/>
        </aside>

    </main>
  )
}

export default Main

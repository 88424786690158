import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchAdminUserAllGet,
  fetchAdminGroupListGet,
  fetchAdminGroupAdd,
  fetchAdminUserUpdate,
  fetchAdminGroupDelete,
  fetchAdminGroupUpdate,
  fetchAdminEmailUpdate,
  fetchAdminLockUpdate,
  fetchAdminEmailModeList,
  fetchAdminModelListGet,
  fetchAdminModelAdd,
  fetchAdminApilogs,
  fetchAdminUserDel,
  fetchAdminModelDelete,
  fetchAdminModelUpdate,
  fetchAdminFileListGetOnfid,
  fetchAdminFileListGet,
  fetchAdminFileAdd,
  fetchAdminFileDelete,
  fetchAdminFGroupListGet,
  fetchAdminFGroupAdd,
  fetchAdminFGroupDelete,
  fetchAdminFGroupUpdate,
  fetchAdminPGroupListGet,
  fetchAdminPGroupAdd,
  fetchAdminPGroupDelete,
  fetchAdminPGroupUpdate,
  fetchAdminPGroupFileListGet,
  fetchAdminPGroupFileAdd,
  fetchAdminChatbotListGet,
  fetchAdminChatbotAdd,
  fetchAdminChatbotDelete,
  fetchAdminRoomListGet,
  fetchAdminAdminFaqs,
  // fetchAdminAdminFeedbackTexts,
  fetchAdminAdminFeedbackLikes,
  fetchAdminAdminFaqAdd,
  fetchAdminTestFeedbacks,
} from "./adminAPI";
import { store } from "../../store";

export interface SideMenuDedetail {
  label: string;
  icon: string;
  to: string;
}

export interface SideMenu {
  label: string;
  items: SideMenuDedetail[];
}

export interface DashboardData {
  user: any;
  group: any;
  model: any;
  file: any;
  fgroup: any;
  pgroup: any;
  pgroupFile: any;
  chatbot: any;
  room: any;
  message: any;
  emailModeList: any;
  apiLog: any;
  feedbackLikes: any;
  faqs: any;
  feedbackTests: any;
  // feedbackTexts: any;
}

// 모델 정의
export interface adminSliceState {
  index: number[];
  status: "idle" | "loading" | "failed";
  sideMenu: SideMenu[];
  dashboard: DashboardData;
}

const initialState: adminSliceState = {
  index: [0, 0],
  status: "idle",
  sideMenu: [
    // {
    //   label: "Home",
    //   items: [{ label: "Dashboard", icon: "bi bi-menu-button-wide", to: "/" }],
    // },
    {
      label: "관리",
      items: [
        {
          label: "사용자/그룹",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/formlayout",
        },
        {
          label: "챗봇/파일",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/input",
        },
        // {
        //   label: "모델",
        //   icon: "bi bi-menu-button-wide",
        //   to: "/uikit/floatlabel",
        // },
        {
          label: "로그/사용 현황",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        {
          label: "FAQ&Rating",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        // {
        //   label: "rating",
        //   icon: "bi bi-menu-button-wide",
        //   to: "/uikit/invalidstate",
        // },
      ],
    },
  ],
  dashboard: {
    user: null,
    group: null,
    model: null,
    file: null,
    fgroup: null,
    pgroup: null,
    pgroupFile: null,
    chatbot: null,
    room: null,
    message: null,
    emailModeList: null,
    apiLog: null,
    feedbackLikes: null,
    faqs: null,
    feedbackTests: null,
    // feedbackTexts: null,
  },
};

export interface AdminGet {
  token: string;
}

export interface AdminFaqRequest extends AdminGet {
  faq_question: string;
  faq_manual: string;
  faq_page: number;
  chatbotId: number;
}
export interface AdminGroupAdd extends AdminGet {
  groupName: string;
  groupDesc: string;
  groupGrd: string;
}
export interface AdminGroupUpdate extends AdminGet {
  groupId: number;
  groupName: string;
  groupDesc: string;
  groupGrd: string;
}
export interface AdminGroupDelete extends AdminGet {
  groupId: number;
}

export interface AdminModelAdd extends AdminGet {
  modelName: string;
  version: string;
  description: string;
  modelMethod: string;
}
export interface AdminModelUpdate extends AdminGet {
  modelId: number;
  modelName: string;
  version: string;
  description: string;
  modelMethod: string;
}
export interface AdminUserUpdate extends AdminGet {
  userId: number;
  groupId: number;
}

export interface AdminModelDelete extends AdminGet {
  modelId: number;
}
export interface AdminFileGetOnfid extends AdminGet {
  fgroupId: number;
}
export interface AdminFileAdd extends AdminGet {
  files: any[];
  mode: string;
}
export interface AdminFileDelete extends AdminGet {
  fileId: number;
}
export interface AdminUserEmailLockUpdate extends AdminGet {
  userId: number;
}

export interface AdminFGroupAdd extends AdminGet {
  fgroupName: string;
}
export interface AdminFGroupUpdate extends AdminGet {
  fgroupId: number;
  fgroupName: string;
}
export interface AdminFGroupDelete extends AdminGet {
  fgroupId: number;
}

export interface AdminPGroupAdd extends AdminGet {
  pgroupName: string;
}
export interface AdminPGroupUpdate extends AdminGet {
  pgroupId: number;
  pgroupName: string;
}
export interface AdminPGroupDelete extends AdminGet {
  pgroupId: number;
}
export interface AdminPGroupFileGet extends AdminGet {
  pgroup_id: number;
}
export interface AdminPGroupFileAdd extends AdminGet {
  pgroup_id: number;
  fids: number[];
}

export interface AdminChatbotAdd extends AdminGet {
  pgroupId: number;
  modelId: number;
}
export interface AdminChatbotDelete extends AdminGet {
  chatbotId: number;
}

// export interface AdminChatbotAdd extends AdminGet {
//   pgroupId: number;
//   modelId: number;
// }
// export interface AdminChatbotDelete extends AdminGet {
//   chatbotId: number;
// }

export const adminSlice = createAppSlice({
  name: "adminPage",
  initialState,
  reducers: (create) => ({
    adminFaqList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminFaqs(adminGetData);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminTestFeedbacks: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminTestFeedbacks(adminGetData);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.feedbackTests = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFaqAdd: create.asyncThunk(
      async (adminFaqRequestData: AdminFaqRequest) => {
        const response = await fetchAdminAdminFaqAdd(adminFaqRequestData);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    // adminFeedbackTexts: create.asyncThunk(
    //   async (adminGetData: AdminGet) => {
    //     const response = await fetchAdminAdminFeedbackTexts(adminGetData);
    //     // console.log("data")
    //     // console.log(response)
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       // console.log("pending")
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       // console.log("fulfilled")
    //       state.status = "idle";
    //       // console.log(action)
    //       state.dashboard!.feedbackTexts = action.payload;
    //     },
    //     rejected: (state) => {
    //       // console.log("failed")
    //       state.status = "failed";
    //     },
    //   }
    // ),
    adminFeedbackLikes: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminFeedbackLikes(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.feedbackLikes = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminUserUpdate: create.asyncThunk(
      async (adminUserUpdateData: AdminUserUpdate) => {
        const response = await fetchAdminUserUpdate(adminUserUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminUserDel: create.asyncThunk(
      async (adminUserDelData: AdminUserEmailLockUpdate) => {
        const response = await fetchAdminUserDel(adminUserDelData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminEmailUpdate: create.asyncThunk(
      async (adminUserEmailUpdateData: AdminUserEmailLockUpdate) => {
        const response = await fetchAdminEmailUpdate(adminUserEmailUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminUserLockUpdate: create.asyncThunk(
      async (adminUserEmailUpdateData: AdminUserEmailLockUpdate) => {
        const response = await fetchAdminLockUpdate(adminUserEmailUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminUserAllGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminUserAllGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.user = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    sidebarSel: create.reducer((state, action: PayloadAction<number[]>) => {
      state.index = action.payload;
    }),
    adminApilogs: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminApilogs(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.apiLog = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminEmailModeList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminEmailModeList(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.emailModeList = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminGroupListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.group = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminGroupAdd: create.asyncThunk(
      async (adminAddData: AdminGroupAdd) => {
        const response = await fetchAdminGroupAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminGroupDelete) => {
        const response = await fetchAdminGroupDelete(adminDeleteData);
        // console.log("data")
        // console.log(response)
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminGroupUpdate) => {
        const response = await fetchAdminGroupUpdate(adminUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminModelListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminModelListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.model = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminModelAdd: create.asyncThunk(
      async (adminAddData: AdminModelAdd) => {
        const response = await fetchAdminModelAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminModelDelete: create.asyncThunk(
      async (adminDeleteData: AdminModelDelete) => {
        const response = await fetchAdminModelDelete(adminDeleteData);
        // console.log("data")
        // console.log(response)
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminModelUpdate: create.asyncThunk(
      async (adminUpdateData: AdminModelUpdate) => {
        const response = await fetchAdminModelUpdate(adminUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminFileListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminFileListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.file = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFileListGetOnfid: create.asyncThunk(
      async (adminGetData: AdminFileGetOnfid) => {
        const response = await fetchAdminFileListGetOnfid(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.fgroup!.dirFile = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFileAdd: create.asyncThunk(
      async (adminAddData: AdminFileAdd) => {
        const response = await fetchAdminFileAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFileDelete: create.asyncThunk(
      async (adminDeleteData: AdminFileDelete) => {
        const response = await fetchAdminFileDelete(adminDeleteData);
        // console.log("data")
        // console.log(response)
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminFGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminFGroupListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          console.log(action.payload);
          state.dashboard!.fgroup = {
            tree: action.payload.file_tree,
            table: action.payload.fgroup,
          };
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFGroupAdd: create.asyncThunk(
      async (adminAddData: AdminFGroupAdd) => {
        const response = await fetchAdminFGroupAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminFGroupDelete) => {
        const response = await fetchAdminFGroupDelete(adminDeleteData);
        // console.log("data")
        // console.log(response)
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminFGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminFGroupUpdate) => {
        const response = await fetchAdminFGroupUpdate(adminUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminPGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminPGroupListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.pgroup = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminPGroupAdd: create.asyncThunk(
      async (adminAddData: AdminPGroupAdd) => {
        const response = await fetchAdminPGroupAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminPGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminPGroupDelete) => {
        const response = await fetchAdminPGroupDelete(adminDeleteData);
        // console.log("data")
        // console.log(response)
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminPGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminPGroupUpdate) => {
        const response = await fetchAdminPGroupUpdate(adminUpdateData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminPGroupFileListGet: create.asyncThunk(
      async (adminGetData: AdminPGroupFileGet) => {
        const response = await fetchAdminPGroupFileListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.pgroupFile = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminPGroupFileAdd: create.asyncThunk(
      async (adminAddData: AdminPGroupFileAdd) => {
        const response = await fetchAdminPGroupFileAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminChatbotListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminChatbotListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.chatbot = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminChatbotAdd: create.asyncThunk(
      async (adminAddData: AdminChatbotAdd) => {
        const response = await fetchAdminChatbotAdd(adminAddData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    adminChatbotDelete: create.asyncThunk(
      async (adminDeleteData: AdminChatbotDelete) => {
        const response = await fetchAdminChatbotDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";

          // console.log(action)
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),

    adminRoomListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminRoomListGet(adminGetData);
        // console.log("data")
        // console.log(response)
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending")
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          // console.log("fulfilled")
          state.status = "idle";
          // console.log(action)
          state.dashboard!.room = action.payload;
        },
        rejected: (state) => {
          // console.log("failed")
          state.status = "failed";
        },
      }
    ),
    // adminRoomAdd: create.asyncThunk(
    //   async (adminAddData: AdminRoomAdd) => {
    //     const response = await fetchAdminRoomAdd(adminAddData);
    //     // console.log("data")
    //     // console.log(response)
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       // console.log("pending")
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       // console.log("fulfilled")
    //       state.status = "idle";
    //       // console.log(action)
    //       // state.dashboard!.group = action.payload
    //     },
    //     rejected: (state) => {
    //       // console.log("failed")
    //       state.status = "failed";
    //     },
    //   },
    // ),
    // adminRoomDelete: create.asyncThunk(
    //   async (adminDeleteData: AdminRoomDelete) => {
    //     const response = await fetchAdminRoomDelete(adminDeleteData);
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       // console.log("pending")
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       // console.log("fulfilled")
    //       state.status = "idle";

    //       // console.log(action)
    //       // state.dashboard!.group = action.payload

    //     },
    //     rejected: (state) => {
    //       // console.log("failed")
    //       state.status = "failed";
    //     },
    //   },
    // ),
  }),

  selectors: {
    selectIndex: (state) => state.index,
    selectSideMenu: (state) => state.sideMenu,
    selectDashboardUser: (state) => state.dashboard!.user,
    selectDashboardGroup: (state) => state.dashboard!.group,
    selectDashboardModel: (state) => state.dashboard!.model,
    selectDashboardFile: (state) => state.dashboard!.file,
    selectDashboardFGroup: (state) => state.dashboard!.fgroup,
    selectDashboardPGroup: (state) => state.dashboard!.pgroup,
    selectDashboardPGroupFile: (state) => state.dashboard!.pgroupFile,
    selectDashboardChatbot: (state) => state.dashboard!.chatbot,
    selectDashboardRoom: (state) => state.dashboard!.room,
    selectDashboardEmailModeList: (state) => state.dashboard!.emailModeList,
    selectDashboardApiLog: (state) => state.dashboard!.apiLog,
    selectDashboardFeedbackLike: (state) => state.dashboard!.feedbackLikes,
    selectDashboardFaqs: (state) => state.dashboard!.faqs,
    selectDashboardTestFeedbacks: (state) => state.dashboard!.feedbackTests,

    // selectDashboardFeedbackTexts: (state) => state.dashboard!.feedbackTexts,
  },
});

export const {
  adminUserAllGet,
  adminGroupListGet,
  adminGroupAdd,
  adminGroupDelete,
  adminGroupUpdate,
  adminModelListGet,
  adminModelAdd,
  adminModelDelete,
  adminModelUpdate,
  adminFileListGet,
  adminFileAdd,
  adminFileDelete,
  adminUserUpdate,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminFGroupAdd,
  adminFGroupDelete,
  adminFGroupUpdate,
  adminFaqList,
  adminPGroupListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminPGroupUpdate,
  adminUserLockUpdate,
  adminEmailUpdate,
  adminPGroupFileListGet,
  adminPGroupFileAdd,
  adminUserDel,
  adminChatbotListGet,
  adminChatbotAdd,
  adminChatbotDelete,
  adminApilogs,
  adminRoomListGet,
  adminEmailModeList,
  adminFeedbackLikes,
  adminFaqAdd,
  // adminFeedbackTexts,
  sidebarSel,
  adminTestFeedbacks,
} = adminSlice.actions;
export const {
  selectIndex,
  selectSideMenu,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardApiLog,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
  selectDashboardRoom,
  selectDashboardEmailModeList,
  selectDashboardFeedbackLike,
  selectDashboardFaqs,
  selectDashboardTestFeedbacks,
  // selectDashboardFeedbackTexts,
} = adminSlice.selectors;

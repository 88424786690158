import React, { useState, useRef, useEffect } from "react";
import "./popover.css";

const Popover = ({ content, handleDocView, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const popoverRef = useRef(null);
  const triggerRef = useRef(null);

  const calculatePosition = () => {
    if (popoverRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const popoverWidth = popoverRef.current.offsetWidth;
      const popoverHeight = popoverRef.current.offsetHeight;

      const viewportHeight = window.innerHeight;

      let top = triggerRect.bottom + window.scrollY;
      let left = triggerRect.left + window.scrollX + triggerRect.width / 2 - popoverWidth / 2;

      // Popover가 화면 아래로 잘리는 경우
      if (top + popoverHeight > viewportHeight + window.scrollY) {
        top = triggerRect.top + window.scrollY - popoverHeight; // 위로 위치 조정
      }

      // 화면 밖으로 벗어나지 않도록 좌우 위치 조정
      if (left < 0) {
        left = 10; // 최소 좌측 여백
      } else if (left + popoverWidth > window.innerWidth) {
        left = window.innerWidth - popoverWidth - 10; // 우측 여백
      }

      setPopoverPosition({ top, left });
    }
  };

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      calculatePosition();
    }
  }, [isOpen]);

  function StringToComponent({ htmlString }) {  
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;  
  }  

  const Contents = StringToComponent({ htmlString: content.replace(/(<br><br>)/g, '<br>') });

  return (
    <div className="messageViewDoc popover-container">
      <div
        onClick={togglePopover}
        ref={triggerRef}
        className="popover-trigger"
      >
        {children}
      </div>
      {isOpen && (
        <div
          ref={popoverRef}
          className="popover-content"
          style={{
            top: popoverPosition.top,
            left: popoverPosition.left,
            transform: "translateX(0)",
          }}
        >
          {Contents}
          <div className="popover-footer">
            <button className="view-document-button" onClick={()=>handleDocView()}>
                View Document</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popover;

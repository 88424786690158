import React, { useState } from 'react'
import ChatSideBar from '../components/main/ChatSideBar'
import ChatMain from '../components/main/ChatMain'
import SideBarDoc from '../components/main/SideBarDoc'
import { VoiceRecordModal } from '../components/main/ChatInput';

function ChatPage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="flex h-full w-full overflow-hidden transition-colors">
       {/* <Header /> */}
       <VoiceRecordModal  modalName="#addVoiceRecordModal"/>

       <ChatSideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
       <ChatMain isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
       {/* <div className="docContents text-base py-[18px] px-3 m-auto h-full md:px-5 lg:px-1 xl:px-5" > */}
        <SideBarDoc />
        
        {/* </div> */}
       {/* <PDFViewer /> */}
    </div>
  )
}

export default ChatPage

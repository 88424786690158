import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  authLogin,
  userRegister,
  selectUser,
  selectStatus,
} from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useSelector } from "react-redux";

function Register(props) {
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordV, setPasswordV] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [pageStatus, setPageStatus] = useState("idle");

  const [selectedLanguage, setSelectedLanguage] = useState("EN")

  const status = useSelector(selectStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pageStatus === "request") {
      if (status === "idle") {
        setPageStatus("Success");
        setTimeout(() => props.mvPage("signin"), 3000);
      }
    }
  }, [status]);

  const onButtonClick = () => {
    // Clear previous error messages
    setEmailError("");
    setPasswordError("");
    setUsernameError("");

    // Validate fields
    if (!email) {
      setEmailError("Please enter your email");
      return;
    }
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    if (!username) {
      setUsernameError("Please enter your username");
      return;
    }
    if (password.length < 4) {
      setPasswordError("The password must be 4 characters or longer");
      return;
    }
    if (password !== passwordV) {
      setPasswordError("Passwords do not match");
      return;
    }

    // Dispatch user registration action
    dispatch(userRegister({ useremail: email, username: username, password: password, lang: selectedLanguage }));
    setPageStatus("request");
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white p-8 rounded-lg">
      <div className="titleContainer text-6xl text-center mb-10 font-bold">Sign Up</div>

      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
        <input
          type="email"
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
        <label className="errorLabel text-red-600 text-sm">{emailError}</label>
      </div>

      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">User Name</label>
        <input
          type="text"
          value={username}
          placeholder="Enter your name here"
          onChange={(ev) => setUserName(ev.target.value)}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
        <label className="errorLabel text-red-600 text-sm">{usernameError}</label>
      </div>

      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input
          type="password"
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
        <label className="errorLabel text-red-600 text-sm">{passwordError}</label>
      </div>

      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">Verify password</label>
        <input
          type="password"
          value={passwordV}
          placeholder="Re-enter your password here"
          onChange={(ev) => setPasswordV(ev.target.value)}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
        <label className="errorLabel text-red-600 text-sm">{passwordError}</label>
      </div>

      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="languageSelect" className="block text-gray-700 text-sm font-bold mb-2">
          Select Language
        </label>
        <select
          id="languageSelect"
          value={selectedLanguage}
          onChange={(ev) => setSelectedLanguage(ev.target.value)}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        >
          <option value="EN">English</option>
          <option value="KR">한국어</option>
        </select>
      </div>


      {pageStatus === "Success" && (
        <p className="text-green-600 mb-4">가입에 성공하였습니다. 3초 뒤 이동합니다.</p>
      )}

      <div className="mb-6">
        <button
          className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-all duration-200"
          onClick={onButtonClick}
        >
          {status === "idle" ? "Create account" : "Creating..."}
        </button>
      </div>
      
      <div className="linkContainer w-full max-w-md flex justify-between mt-6 text-blue-500 underline cursor-pointer text-sm">
        <p className="mb-2 cursor-pointer hover:underline" onClick={() => props.mvPage("signin")}>
          Already have an account?
        </p>
        <p className="cursor-pointer hover:underline" onClick={() => props.mvPage("findpw")}>
          Forgot the password?
        </p>
      </div>
    </div>
  );
}

export default Register;

import React, { useEffect, useState } from "react";
import profileImg from "../../images/avata_1.jpg";

import {
  authLogout,
  selectUser,
  selectToken,
  userLangChange,
} from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import "bootstrap/js/dist/dropdown";
import { useNavigate } from "react-router-dom";

function NavAvatar() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);

  const [currentLanguage, setCurrentLanguage] = useState(user.user_lang);

  console.log({user});
  useEffect(() => {
    if (user == null) {
      navigate("/");
    }
  }, user);

  const navigate = useNavigate();
  const handleSignOut = () => {
    dispatch(authLogout(token));
  };

  const handleLanguageChange  = (language) => {
    setCurrentLanguage(language);
    changeLanguage(language);
    dispatch(userLangChange({token, lang:language}))
  };
  
  const changeLanguage = (language) => {
    //i18n.changeLanguage(language);
    console.log("Selected language:", language);
  };
  console.log({currentLanguage});
  return (
    <>
      {user ? (
        <li className="nav-item dropdown pe-3">
          <a
            className="nav-link nav-profile d-flex align-items-center pe-0"
            href="#"
            data-bs-toggle="dropdown"
          >
            {/* <img src={profileImg} alt="Profile" className="rounded-circle" /> */}
            <span className="d-none d-md-block dropdown-toggle ps-2">
              {user.user_id == 1 ? "Administrator" : user.user_name}
            </span>
          </a>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li className="dropdown-header">
              <h6>{user.user_id == 1 ? "Administrator" : user.user_name}</h6>
              <span>{user.Group.group_name}</span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a
                className="dropdown-item d-flex align-items-center"
                onClick={() => navigate("/chat")}
              >
                <i className="bi bi-chat-dots"></i>
                <span>QA Chat Page</span>
              </a>
            </li>
            {user.user_id == 1 ? (
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => navigate("/dashboard")}
                >
                  <i className="bi bi-person"></i>
                  <span>Dashboard</span>
                </a>
              </li>
            ) : (
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => {}}
                >
                  <i className="bi bi-person"></i>
                  <span>My Profile</span>
                </a>
              </li>
            )}
            <li>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <i className="bi bi-gear"></i>
                <span>Account Settings</span>
              </a>
            </li>
            <li>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <i className="bi bi-question-circle"></i>
                <span>Need Help?</span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
             <a className="dropdown-item d-flex align-items-center" href="#">
             <div className="d-flex align-items-center px-1">
                <i className="bi bi-globe me-2"></i>
                <span className="me-4">Language: </span>
                <button
                  className={`btn btn-sm me-1 ${currentLanguage === "EN" ? "btn-primary" : "btn-outline-secondary"}`}
                  onClick={() => handleLanguageChange("EN")}
                >
                  EN
                </button>
                <button
                  className={`btn btn-sm ${currentLanguage === "KR" ? "btn-primary" : "btn-outline-secondary"}`}
                  onClick={() => handleLanguageChange("KR")}
                >
                  KR
                </button>
              </div>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            
            <li>
              <a
                className="dropdown-item d-flex align-items-center"
                onClick={() => handleSignOut()}
                href="#"
              >
                <i className="bi bi-box-arrow-right"></i>
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
        </li>
      ) : (
        <></>
      )}
    </>
  );
}

export default NavAvatar;

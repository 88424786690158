import React from 'react'
import './header.css'
import './Logo'
import Logo from './Logo';
import SearchBar from './SearchBar';
import Nav from './Nav';
import { useNavigate } from "react-router-dom";
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

const resources = {
    en: {
      translation: {
        greeting: "Hello",
        changeLanguage: "Change Language"
      }
    },
    ko: {
      translation: {
        greeting: "안녕하세요",
        changeLanguage: "언어 변경"
      }
    }
  };


i18n
  .use(initReactI18next) // i18n을 react에 바인딩
  .init({
    resources,
    lng: "en", // 기본 언어
    keySeparator: false, 
    interpolation: {
      escapeValue: false // React는 자동으로 XSS를 방지
    }
  });
function Header({isOpen, setIsOpen}) {
    const navigate = useNavigate();

    const { t } = useTranslation(); 

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (
        <header 
            id='header' 
            className={`header absolute left-0 right-0 ${isOpen? 'left-[260px]': 'left-0'}`}>
                <div className="sticky top-0 p-3 mb-1.5 flex items-center justify-between z-10 h-14 font-semibold bg-token-main-surface-primary">
                    <Logo isOpen={isOpen} setIsOpen={setIsOpen}/>
                    {/* <SearchBar /> */}
                    {/* <a onClick={() => navigate("/menu")}>menu</a>
                    <a onClick={() => navigate("/service")}>service</a> */}
                    {/* <div className="flex space-x-2">
                        {t('greeting')}
                        <button
                            onClick={() => changeLanguage('en')}
                            className="px-1 py-1 text-xs bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        >
                            English
                        </button>
                        <button
                            onClick={() => changeLanguage('ko')}
                            className="px-1 py-1  text-xs bg-red-500 text-white rounded-md hover:bg-red-600"
                        >
                            한국어
                        </button>
                    </div> */}
                    <Nav />
                </div>
        </header>
    )
}

export default Header;
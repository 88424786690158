import React from 'react'

function NavNotice() {
  return (
    <li className='nav-item dropdown'>
      <a className='nav-link nav-icon' href="#" data-bs-toggle='dropdown' aria-expanded="true">
        <i className='bi bi-bell'></i>
        <span className='badge bg-primary badge-number'>4</span>
      </a>
      <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications'>
        <li className="dropdown-header">
            You have 4 new notifications
            <a href="#">
                <span className="badge rounded-pill bg-primary p-2 ms-2">
                    Vuew all
                </span>
            </a>
        </li>
        <li>
            <hr className="dropdown-divider"/>
        </li>
        <li className='notifications-item'>
            <i className="bi bi-exclamation-circle text-warning"></i>
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earun veritatis oditseno</p>
                <p>30 min. ago</p>
            </div>
        </li>
        <li>
            <hr className="dropdown-divider"/>
        </li>
        <li className='notifications-item'>
            <i className="bi bi-exclamation-circle text-danger"></i>
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earun veritatis oditseno</p>
                <p>30 min. ago</p>
            </div>
        </li>
        <li>
            <hr className="dropdown-divider"/>
        </li>
        <li className='notifications-item'>
            <i className="bi bi-exclamation-circle text-success"></i>
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earun veritatis oditseno</p>
                <p>30 min. ago</p>
            </div>
        </li>
        <li>
            <hr className="dropdown-divider"/>
        </li>
        <li className='notifications-item'>
            <i className="bi bi-exclamation-circle text-primary"></i>
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earun veritatis oditseno</p>
                <p>30 min. ago</p>
            </div>
        </li>
        <li>
            <hr className="dropdown-divider"/>
        </li>
        <li className='dropdown-footer'>
            <a href='#'>Show all Notifications</a>
            
        </li>


      </ul>
    </li>
  )
}

export default NavNotice

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  selectUser,
  selectToken,
  selectStatus,
  selectErrorMsg,
} from "../../lib/features/auth/authSlice";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState(""); // General error message

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector(selectStatus);
  const errorMsg = useAppSelector(selectErrorMsg);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);

  useEffect(() => {
    if (errorMsg) {
      setGeneralError(errorMsg);
    }
  }, [errorMsg]);

  const onButtonClick = () => {
    setEmailError("");
    setPasswordError("");
    setGeneralError("");

    if ("" === email) {
      setEmailError("Please enter your email");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 4) {
      setPasswordError("The password must be 4 characters or longer");
      return;
    }

    // Dispatching login action
    dispatch(authLogin({ useremail: email, password: password }))
      .unwrap()
      .then((response) => {
        // Handle successful login
        console.log({group_id:response.user.group_id})
        if(response.user.group_id==1){
          navigate("/dashboard");
          return  
        }
        navigate("/menu");
      })
      .catch((error) => {
        // Handle login failure
        setGeneralError("Login failed: Incorrect email or password");
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onButtonClick();
    }
  };

  return (
    <div className="LoginContainer flex flex-col items-center justify-center h-screen w-3/4">
      <div className="titleContainer text-6xl text-center mb-10 font-bold">
        <div>Login</div>
      </div>
      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
        <input
          type="email"
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          onKeyDown={handleKeyPress}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {emailError && <label className="errorLabel text-red-500 text-xs mt-2">{emailError}</label>}
      </div>
      <div className="inputContainer mb-6 w-full max-w-md">
        <label htmlFor="inputPassword1" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input
          type="password"
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          onKeyDown={handleKeyPress}
          className="inputBox w-full p-3 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {passwordError && <label className="errorLabel text-red-500 text-xs mt-2">{passwordError}</label>}
      </div>
      {generalError && (
        <div className="errorContainer w-full max-w-md text-center text-red-500 text-sm mb-4">
          {generalError}
        </div>
      )}
      <div className="inputBtnContainer w-full max-w-md">
        <input
          className="inputButton bg-blue-500 text-white font-semibold py-3 px-6 rounded-lg w-full hover:bg-blue-600 cursor-pointer"
          type="button"
          onClick={onButtonClick}
          value="Log in"
        />
      </div>
      <div className="linkContainer w-full max-w-md flex justify-between mt-6 text-blue-500 underline cursor-pointer text-sm">
        <div onClick={() => props.mvPage("signup")}>Create account?</div>
        <div onClick={() => props.mvPage("findpw")}>Forgot the password?</div>
      </div>
    </div>
  );
};

export default Login;

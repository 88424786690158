import React, { useEffect, useState } from "react";
import {
  adminUserAllGet,
  adminGroupListGet,
  adminGroupAdd,
  adminGroupDelete,
  adminGroupUpdate,
  adminEmailModeList,
  adminUserDel,
  selectDashboardEmailModeList,
  adminUserUpdate,
  selectDashboardUser,
  selectDashboardGroup,
  adminEmailUpdate,
  adminUserLockUpdate,
} from "../../../lib/features/admin/adminSlice";
import {
  selectUser,
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

function AdminUserPage() {
  const [userInfo, setUserInfo] = useState(null);

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectDashboardUser);
  const groups = useAppSelector(selectDashboardGroup);
  const token = useAppSelector(selectToken);
  const status = useAppSelector(selectStatus);
  // const emailModeList = useAppSelector(selectDashboardEmailModeList);

  const [userData, setUserData] = useState(users);
  const [groupData, setGroupData] = useState(groups);

  // 각 사용자별로 선택된 그룹 ID를 관리하는 상태
  const [selectedGroupIds, setSelectedGroupIds] = useState({});

  // 그룹 선택시 상태 업데이트
  const handleGroupChange = (userId, groupId) => {
    setSelectedGroupIds((prevSelectedGroupIds) => ({
      ...prevSelectedGroupIds,
      [userId]: groupId,
    }));
  };

  useEffect(() => {
    // console.log("AdminUserPage:useEffect")
    dispatch(adminUserAllGet({ token }));
    dispatch(adminGroupListGet({ token }));
    // dispatch(adminGroupListGet({ token }));
    // setUserData(users)
    // setGroupData(groups)
  }, []);
  // dispatch(adminUserAllGet({token}));
  // console.log("AdminUserPage");
  // console.log(users);
  // // console.log(token)
  // console.log(status);

  const handleShowUserInfo = (user) => {
    setUserInfo(user);
  };
  const handleAddGroup = (groupName, groupDesc, groupGrd) => {
    dispatch(adminGroupAdd({ token, groupName, groupDesc, groupGrd }));
    // document.querySelector('#addRoomModal')({
    //   show: false
    // })
    //console.log(document.getElementsByClassName("modal fade show"))
    window.confirm("추가되었습니다.");
    dispatch(adminGroupListGet({ token }));
    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };
  const handleDeleteGroup = (groupId) => {
    dispatch(adminGroupDelete({ token, groupId })).then(() => {
      dispatch(adminGroupListGet({ token })).then(() => setGroupData(groups));
    });

    // console.log(1);

    // setGroupData(groups)
  };
  const handleUpdateGroup = (groupName, groupDesc, groupGrd) => {
    dispatch(adminGroupAdd({ token, groupName, groupDesc, groupGrd }));
    dispatch(adminGroupListGet({ token }));
    setGroupData(groups);
  };


  const handleUnlock = (userId) => {
    dispatch(adminUserLockUpdate({ token, userId })).then(() => {
      dispatch(adminUserAllGet({ token }));
    });
  };


  const handleEmailCheck = (userId) => {
    dispatch(adminEmailUpdate({ token, userId })).then(() => {
      dispatch(adminUserAllGet({ token }));
    });
  };

  const handleDelete = (userId) => {
    dispatch(adminUserDel({ token, userId }));
  };


  const handleModify = (userId) => {
    const groupId = selectedGroupIds[userId];
    if (groupId) {
      dispatch(adminUserUpdate({ token, userId, groupId })).then(() => {
        dispatch(adminUserAllGet({ token }));
      });

    } else {
      alert("그룹을 선택해주세요.");
    }
  };

  const strnig2Date = (s) => {
    const year = s.substring(0, 4);
    const month = s.substring(4, 6);
    const day = s.substring(6, 8);

    return `${year}/${month}/${day}`;
  }

  return (
    <div>
      <br />
      <br />

      <div className="contentPanel">
        <h3>Users</h3>
        <hr />
        <table>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Group</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Admission</th>
              <th scope="col">Email Checked</th>
              <th scope="col">Failed</th>
              <th scope="col">Register Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.users.map((user, idx) => (
                <tr key={user.user_id}>
                  <td scope="row">{idx + 1}</td>
                  <td>{user.Group.group_name}</td>
                  <td>{user.user_name}</td>
                  <td>{user.user_email}</td>
                  <td>{user.is_locked | !user.has_email_check ? "Not approved" : "Approved"}</td>
                  <td>{!user.has_email_check ? "Not Checked" : "Checked"}</td>
                  <td>{user.lock_cms}</td>
                  <td>{strnig2Date(user.created_at)}</td>
                  <td>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id={`dropdownMenuButton-${user.user_id}`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${user.user_id}`}>
                        <li>
                          <select
                            className="dropdown-item"
                            value={selectedGroupIds[user.user_id] || ""}
                            onChange={(e) => handleGroupChange(user.user_id, e.target.value)}
                          >
                            <option value="">Sel Group</option>
                            {groups &&
                              groups.map((group) => (
                                <option key={group.group_id} value={group.group_id}>
                                  {group.group_name}
                                </option>
                              ))}
                          </select>
                        </li>

                        <li>
                          <button className="dropdown-item" onClick={() => handleModify(user.user_id)}>
                            Modify
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => handleShowUserInfo(user)}>
                            Info
                          </button>
                        </li>
                        {user.is_locked == 1 && (
                          <li>
                            <button className="dropdown-item" onClick={() => handleUnlock(user.user_id)}>
                              Unlock
                            </button>
                          </li>
                        )}
                        {user.has_email_check == 0 && (
                          <li>
                            <button className="dropdown-item" onClick={() => handleEmailCheck(user.user_id)}>
                              Email Checked
                            </button>
                          </li>
                        )}
                        <li>
                          <button className="dropdown-item" onClick={() => handleDelete(user.user_id)}>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <br />

      <div className="contentPanel">
        <div className="flex justify-between">
          <h3>GROUP</h3>

          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#addRoomModal"
          >
            새 그룹 추가하기
          </button>
          <GroupAdd modalName="#addRoomModal" />
        </div>
        <hr />
        <table>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Group Name</th>
              <th scope="col">관리자</th>
              <th scope="col">내/외부</th>
              <th scope="col">그룹설명</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {groups &&
              groups.map((group, idx) => (
                <tr key={group.group_id}>
                  <th scope="row">{idx + 1}</th>
                  <td>{group.group_name}</td>
                  <td>{group.group_grd.split('_')[0] == "1" ? "Y" : "N"}</td>
                  <td>{group.group_grd.split('_')[1] == "01" ? "내부" : group.group_grd.split('_')[1] == "02" ? "외부" : "미분류"}</td>
                  <td>{group.group_description}</td>
                  <td>
                    {/* 드롭다운 메뉴 */}
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id={`dropdownMenuButton-${group.group_id}`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${group.group_id}`}>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => handleUpdateGroup(group.group_id)}
                          >
                            Modify
                          </button>
                        </li>
                        {(group.group_grd.split('_')[0] != "1") &&
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => handleDeleteGroup(group.group_id)}
                            >
                              Delete
                            </button>
                          </li>
                        }
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* 페이지 네비게이션 */}
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <br />
    </div>
  );
}

function GroupAdd(props) {
  const { modalName } = props.modalName;

  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupDesc, setNewGroupDesc] = useState("");
  const [newGroupGrd, setNewGroupGrd] = useState("");

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectDashboardUser);
  const groups = useAppSelector(selectDashboardGroup);
  const token = useAppSelector(selectToken);

  // useEffect(() => {
  //   console.log("AdminUserPage:useEffect")
  //   console.log(token)
  //   dispatch(adminUserAllGet({ token }));
  //   dispatch(adminGroupListGet({ token }));
  // }, [userData, groupData])

  const handleAddGroup = (groupName, groupDesc, groupGrd) => {
    dispatch(adminGroupAdd({ token, groupName, groupDesc, groupGrd })).then(
      () => {
        dispatch(adminGroupListGet({ token }));
      }
    );
    window.confirm("추가되었습니다.");
    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };

  return (
    <div
      class="modal fade"
      id="addRoomModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              그룹 추가
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            그룹 추가 form
            <form action="">
              <label htmlFor="">그룹명</label>
              <input
                type="text"
                value={newGroupName}
                onChange={(ev) => setNewGroupName(ev.target.value)}
              />
              <br />
              <label htmlFor="">그룹권한</label>
              <input
                type="text"
                value={newGroupGrd}
                onChange={(ev) => setNewGroupGrd(ev.target.value)}
              />
              <br />
              <label htmlFor="">그룹설명</label>
              <input
                type="text"
                value={newGroupDesc}
                onChange={(ev) => setNewGroupDesc(ev.target.value)}
              />
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() =>
                handleAddGroup(newGroupName, newGroupDesc, newGroupGrd)
              }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUserPage;

import React, { Children, useEffect, useState } from "react";
import {
  adminUserAllGet,
  adminGroupListGet,
  adminGroupAdd,
  adminGroupDelete,
  adminGroupUpdate,
  adminModelListGet,
  adminModelAdd,
  adminModelDelete,
  adminModelUpdate,
  adminFileListGet,
  adminFileAdd,
  adminFileDelete,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminFGroupAdd,
  adminFGroupDelete,
  adminFGroupUpdate,
  adminPGroupListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminPGroupUpdate,
  adminPGroupFileListGet,
  adminPGroupFileAdd,
  adminChatbotListGet,
  adminChatbotAdd,
  adminChatbotDelete,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
} from "../../../lib/features/admin/adminSlice";
import {
  selectUser,
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

import FolderTree, { testData } from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import { FaTrashAlt } from "react-icons/fa";
// import FileTree from '../FileTree';

function AdminChatbotPage() {
  const [userInfo, setUserInfo] = useState(null);

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectDashboardUser);
  const groups = useAppSelector(selectDashboardGroup);
  const models = useAppSelector(selectDashboardModel);
  const files = useAppSelector(selectDashboardFile);
  const fgroups = useAppSelector(selectDashboardFGroup);
  const pgroups = useAppSelector(selectDashboardPGroup);
  const pgroupFiles = useAppSelector(selectDashboardPGroupFile);
  const chatbots = useAppSelector(selectDashboardChatbot);
  const token = useAppSelector(selectToken);
  const status = useAppSelector(selectStatus);

  const [selectFiles, setSelectFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [selectDirName, setSelectDirName] = useState("");
  const [selectGroup, setSelectGroup] = useState({});

  const handleFilesChange = (e) => {
    setUploadFiles(Array.from(e.target.files));
  };
  const handleUploadFile = () => {
    dispatch(
      adminFileAdd({ token: token, files: uploadFiles, mode: "pdf" })
    ).then(() => dispatch(adminFileListGet({ token })));
  };
  const handleDeleteFile = (fileIdx) => {
    dispatch(
      adminFileDelete({
        token: token,
        fileId: files.recentPdfs.filter((file) => !file.is_deleted)[fileIdx]
          .pdf_id,
      })
    ).then(() => dispatch(adminFileListGet({ token })));
  };

  useEffect(() => {
    // console.log("AdminUserPage:useEffect")
    dispatch(adminUserAllGet({ token }));
    dispatch(adminGroupListGet({ token }));
    dispatch(adminModelListGet({ token }));
    dispatch(adminFileListGet({ token }));
    dispatch(adminFGroupListGet({ token }));
    dispatch(adminPGroupListGet({ token }));
    dispatch(adminChatbotListGet({ token }));

    // setUserData(users)
    // setGroupData(groups)
  }, []);
  // dispatch(adminUserAllGet({token}));
  // console.log("AdminUserPage");
  // console.log(users);
  // // console.log(token)
  // console.log(status);
  // console.log(files);
  // console.log(fgroups);
  // console.log(pgroups);
  // console.log(pgroupFiles);
  // console.log(chatbots);

  // console.log("selectFiles");
  // console.log(selectFiles);

  const handleShowUserInfo = (user) => {
    setUserInfo(user);
  };
  const handleAddModel = (groupName, groupDesc, groupGrd) => {
    dispatch(adminModelAdd({ token, groupName, groupDesc, groupGrd })).then(
      () => dispatch(adminModelListGet({ token }))
    );
    // document.querySelector('#addRoomModal')({
    //   show: false
    // })
    //console.log(document.getElementsByClassName("modal fade show"))
    window.confirm("추가되었습니다.");

    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };
  const handleDeleteModel = (modelId) => {
    dispatch(adminModelDelete({ token, modelId })).then(() => {
      dispatch(adminModelListGet({ token }));
    });
  };
  const handleDeleteFGroup = (fgroupId) => {
    dispatch(adminFGroupDelete({ token, fgroupId })).then(() => {
      dispatch(adminFGroupListGet({ token }));
    });
  };
  const handleUpdateModel = (groupName, groupDesc, groupGrd) => {
    dispatch(adminModelAdd({ token, groupName, groupDesc, groupGrd })).then(
      () => {
        dispatch(adminModelListGet({ token }));
      }
    );
  };

  const handleSelectFile = (file) => {
    // console.log("ft.handleSelectFile");
    // console.log(file);
    // console.log(selectFiles);
    const is_dupli = selectFiles.filter((e) => {
      return e.pdf_id === file.pdf_id;
    });
    // console.log("ft.handleSelectFile is_dupli");
    // console.log(is_dupli.length);
    if (is_dupli.length > 0) {
      return;
    }
    // console.log("ft.handleSelectFile Add");
    // console.log(selectFiles);
    setSelectFiles([...selectFiles, file]);
  };

  const handleDeletePGroup = (pgroupId) => {
    dispatch(adminPGroupDelete({ token, pgroupId })).then(() => {
      dispatch(adminPGroupListGet({ token }));
    });
  };

  const handleDeleteChatbot = (chatbotId) => {
    // dispatch(adminPGroupDelete({ token, pgroupId })).then(()=>{
    //   dispatch(adminPGroupListGet({ token }))
    // })
  };

  const handleClickPGroup = (pgroupId) => {
    dispatch(adminPGroupFileListGet({ token, pgroup_id: pgroupId }));
  };

  const mktree = (tree) => {
    // console.log(tree)
    return tree.child.length
      ? {
        name: tree.fgroup_name,
        fid: tree.fgroup_id,
        checked: 0,
        isOpen: false,
        children: tree.child.map((t) => mktree(t)),
      }
      : {
        name: tree.fgroup_name,
        fid: tree.fgroup_id,
        checked: 0,
        isOpen: false,
      };
  };
  const treeStateDir = fgroups ? mktree(fgroups.tree) : [];

  // console.log(treeStateDir)

  const treeStateFiles = {
    name: "Trouble shoothing manual",
    checked: 0, // half check: some children are checked
    isOpen: true, // this folder is opened, we can see it's children
    children: files
      ? files
        .filter((file) => !file.is_deleted)
        .map((file) => {
          return { name: file.original_name, isOpen: false };
        })
      : [],
  };
  
  const handleOnRemoveSelFile= (fid) => {
    console.log(selectFiles)
    setSelectFiles(selectFiles.filter((item, idx)=>item.pdf_id!==fid))
  }

  const [newPGroupName, setNewPGroupName] = useState("");

  const handleAddPGroup = (pgroupName) => {
    // console.log({ token, modelName, modelDesc, modelMethod, modelVersion })
    dispatch(adminPGroupAdd({ token, pgroupName })).then((action) => {
      console.log({action})
      dispatch(adminPGroupListGet({ token }));
      // dispatch(adminPGroupFileAdd({ token,  action.pgroup_id, fids:[] })).then(() => {
      //   dispatch(adminPGroupListGet({ token }));
      // });
    });
    setNewPGroupName("")
  };

  return (
    <div>
      {/* 파일 리스트 /api/user/all/get
      <br />
      처음 들어오면 `파일묶음`(pgroup)이 보이도록 한다.
      <br />
      pgroup목록이 보이며, 선택하면 연결된 파일 목록이 보이도록 한다.
      <br />
      파일 묶음을 추가하도록 하면, 전체 파일 리스트가 보이며 선택하여 만들수
      있도록 한다.
      <br />
      전체파일은 폴더형태로 관리 가능해야한다.
      <br />
      업로드된 파일리스트가 tree로 보여야한다. 옆 메뉴 선택하면 구체적인 파일
      보이도록.
      <br />
      트리 구조는 미리 지정하고, 왼쪽에서 보이도록 함
      <br />
      각 폴더의 파일은 리스트가 보이도록 함 (파일은 이름 수정/삭제 가능하도록,
      이동은 추후.)
      <br />
      <br />
      [추가] !!! 업로드 UI 및 테스트 (구역 나눠야하니 trouble shooting에 고정!) */}
      <br />
      <div className="contentPanel">
        {/* <div class="input-group" style={{ width: "50%" }}>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="inputGroupFile"
              onChange={handleFilesChange}
              multiple
            />
            <label class="custom-file-label" for="inputGroupFile04">
              Choose file
            </label>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              onClick={handleUploadFile}
            >
              Button
            </button>
          </div>
        </div> */}

        <br />
        <div style={{ display: "flex", justifyContent: "stretch" }}>
          
          <div className="" style={{ minWidth: "300px" }}>
          <h2 className="text-lg font-bold mb-4">Directory</h2>
            {/* <BasicTree data={treeStateDir} /> */}
            <FileTree data={treeStateDir} />
          </div>
          <div style={{ width: "60%" }}>
            {/* <BasicTree data={treeStateFiles} deleteFt={handleDeleteFile} /> */}
            <h2 className="text-lg font-bold mb-4">Files </h2>
            <div className="bg-white rounded-md shadow-md p-2 h-[300px] overflow-scroll overflow-x-hidden">
              {fgroups && fgroups.dirFile && fgroups.dirFile.map((file) => (
                <div className="flex items-center justify-between px-1 border-b">
                  <div className="flex items-center">
                    {/* 선택 버튼 */}
                    <input
                      type="checkbox"
                      className="mr-2"
                      onChange={(e) => handleSelectFile(file)}
                      checked={selectFiles.findIndex((selectedFile) => selectedFile.pdf_id === file.pdf_id) !== -1}
                    />
                    {/* 파일 이름 */}
                    <span className="w-5">
                      <svg
                        className="w-5 h-5"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 303.188 303.188"
                      >
                        <g>
                          <polygon
                            style={{ fill: "#E8E8E8" }}
                            points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525"
                          />
                          <path
                            style={{ fill: "#FB3449" }}
                            d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                            C231.127,152.765,230.756,151.257,230.013,149.935z"
                          />
                          <polygon
                            style={{ fill: "#FB3449" }}
                            points="227.64,25.263 32.842,25.263 32.842,0 219.821,0"
                          />
                          <g>
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                              v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                              M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                              h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"
                            />
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                              h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                              c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                              C160.841,257.523,161.76,254.028,161.76,249.324z"
                            />
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                              L196.579,273.871L196.579,273.871z"
                            />
                          </g>
                          <polygon style={{ fill: "#D1D3D3" }} points="219.821,50.525 270.346,50.525 219.821,0" />
                        </g>
                      </svg>

                    </span>
                    <p className="w-[70%] truncate my-1">{file.original_nm}</p>
                  </div>
                </div>
              ))}
            </div>
            <ul>
              {/* {fgroups &&
                fgroups.dirFile &&
                fgroups.dirFile.map((file) => (
                  <li>
                    <button onClick={() => handleSelectFile(file)}>선택</button>
                    <span>{file.original_nm}</span>
                  </li>
                ))} */}

            </ul>
          </div>
        </div>

        <br />
        <div className="w-full p-4 bg-white rounded-lg shadow-md ">
          <h2 className="text-lg font-bold mb-4">선택한 파일 목록</h2>
          <ul className="list-disc pl-5 space-y-2 h-[300px] overflow-scroll overflow-x-hidden">
            {selectFiles &&
              selectFiles
                .filter((file) => file.is_deleted === 0)
                .map((file) => (
                  <li key={file.pdf_id} className="flex justify-start items-center  border-b">
                    <span className="w-5">
                      <svg
                        className="w-5 h-5"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 303.188 303.188"
                      >
                        <g>
                          <polygon
                            style={{ fill: "#E8E8E8" }}
                            points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525"
                          />
                          <path
                            style={{ fill: "#FB3449" }}
                            d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                            C231.127,152.765,230.756,151.257,230.013,149.935z"
                          />
                          <polygon
                            style={{ fill: "#FB3449" }}
                            points="227.64,25.263 32.842,25.263 32.842,0 219.821,0"
                          />
                          <g>
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                              v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                              M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                              h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"
                            />
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                              h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                              c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                              C160.841,257.523,161.76,254.028,161.76,249.324z"
                            />
                            <path
                              style={{ fill: "#A4A9AD" }}
                              d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                              L196.579,273.871L196.579,273.871z"
                            />
                          </g>
                          <polygon style={{ fill: "#D1D3D3" }} points="219.821,50.525 270.346,50.525 219.821,0" />
                        </g>
                      </svg>

                    </span>
                    <span>{file.original_nm}</span>
                    {/* 삭제 버튼 */}
                    <button
                      onClick={() => handleOnRemoveSelFile(file.pdf_id)}
                      className="text-red-500 hover:text-red-700 ml-4 min-w-10"
                    >
                      삭제
                    </button>
                  </li>
                ))}
          </ul>
        </div>
        
        
        
        <div class="pdf-group-add-form flex justify-start items-center gap-2 mt-2 p-2 border rounded-lg">
            <label className="w-[200px] text-l font-bold" htmlFor="">New Group Name</label>
            <input
              className="w-[100px] h-10 bg-gray-100"
              type="text"
              value={newPGroupName}
              onChange={(ev) => setNewPGroupName(ev.target.value)}
            />

            <button 
              onClick={()=>handleAddPGroup(newPGroupName)}
              className="w-full h-10 p-2 bg-blue-300 rounded-md text-l font-bold">
              선택한 파일로 챗봇 생성
            </button>
          </div>
      </div>
      <br />
      <br />
      {/* <h3> 파일 그룹</h3>
      <hr />
      파일 그룹 리스트 보기 /api/model/list
      <table style={{ border: '3px solid purple' }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">fgroup_id</th>
            <th scope="col">fgroup_name</th>
            <th scope="col">fgroup_pid</th>
            <th scope="col">func</th>
          </tr>
        </thead>
        <tbody>
          {fgroups && fgroups.table.map((fgroup, idx) => {
            return <tr>
              <th scope="row">{idx}</th>
              <td>{fgroup.fgroup_id}</td>
              <td>{fgroup.fgroup_name}</td>
              <td>{fgroup.fgroup_pid}</td>
              <td style={{ dispaly: 'flex', gap: '10px' }}>
                <span
                  style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                  onClick={() => handleDeleteFGroup(fgroup.fgroup_id)}
                >delete</span>
                <span
                  style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                >modify</span>
              </td>
            </tr>
          })}
        </tbody>
      </table>
      <br /><br />
      파일 그룹 추가 /api/pgroup/1
      <div>
        <button type="button" class="btn" data-toggle="modal" data-target="#addFGroupModal">
          추가하기
        </button>

        <FGroupAdd modalName={"addFGroupModal"} />
        
      </div> */}
      {/* <div>
        <button
          type="button"
          class="btn"
          data-toggle="modal"
          data-target="#addPGroupModal"
        >
          추가하기
        </button>

        
      </div> */}

      <div className="contentPanel">
        <h3>PDF Groups</h3>
        <hr />
        <table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col">Group Name</th>
            <th scope="col">func</th>
          </tr>
        </thead>
        <tbody>
          {pgroups &&
            pgroups.map((pgroup, idx) => {
              return (
                <tr>
                  <th scope="row">{idx}</th>
                  <td>{pgroup.pgroup_id}</td>
                  <td>
                    <span onClick={() => handleClickPGroup(pgroup.pgroup_id)}>
                      {pgroup.pgroup_name}
                    </span>
                  </td>
                  <td style={{ dispaly: "flex", gap: "10px" }}>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                      onClick={() => handleDeletePGroup(pgroup.pgroup_id)}
                    >
                      delete
                    </span>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                    >
                      modify
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            {/* <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li> */}
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>

        <div >
          
          <br />
          선택한 그룹: {pgroupFiles ? pgroupFiles.pgroup.pgroup_name : "없음"}
          <ul className="border">
            {pgroupFiles &&
              pgroupFiles.pdf
                .filter((file) => file.is_deleted == 0)
                .map((file) => <li>{file.original_nm}</li>)}
          </ul>
        </div>

      </div>
      <br />
      
      
      {/* <div  style={{width: "90%", border: '3px solid purple'  }}>  
        적용 가능한 모델 선택(이게 없구나....) 모달로 선택? 드랍다운으로 구현?
        <div style={{display:"flex", gap: "3px"}}>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              리트리벌 모델 선택
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {models&&models.map((model, idx)=>{
                return  <a class="dropdown-item" href="#">{model.model_name}</a>
              })}
            </div>
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              LLM 모델 선택
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              {models&&models.map((model, idx)=>{
                return  <a class="dropdown-item" href="#">{model.model_name}</a>
              })}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div style={{ width: "90%", border: "3px solid purple" }}>
        적용 유저 또는 그룹 선택(창을 띄워서 찾게 해야하는 건가..)
        <br />
        선택된 그룹: {selectGroup.group_name}
        <div style={{ display: "flex", gap: "3px" }}>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              적용 그룹 선택
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {groups &&
                groups.map((group, idx) => (
                  <span
                    class="dropdown-item"
                    onClick={() => setSelectGroup(group)}
                  >
                    {group.group_name}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "90%", border: "3px solid purple" }}>
        생성 하면 모델에 등록 하면 방이 뜨게됨
        <button> 챗봇 생성버튼 </button>
      </div> */}
      {/* <br />
      모델-유저 또는 모델-그룹 간에 table이 필요함. 이용권한을 지정해주기 위함.
      <br />
      관리자가 사용 모델을 지정하며, 사용자는 권한 기반으로 자동으로 방
      생성(추가)이 됨.
      <br /> */}

      <div className="contentPanel">
        <h3>Chatbots</h3>
        <hr />
        <table>
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">ID</th>
            <th scope="col">PDF Group</th>
            {/* <th scope="col">model_id</th> */}
            <th scope="col">func</th>
          </tr>
        </thead>
        <tbody>
          {chatbots &&
            chatbots.map((chatbot, idx) => {
              return (
                <tr>
                  <th scope="row">{idx}</th>
                  <td>{chatbot.chatbot_id}</td>
                  <td>{chatbot.pgroup_id}</td>
                  {/* <td>{chatbot.model_id}</td> */}
                  <td style={{ dispaly: "flex", gap: "10px" }}>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                      onClick={() => handleDeleteChatbot(chatbot.chatbot_id)}
                    >
                      delete
                    </span>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                    >
                      modify
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      </div>
      <br />
      {/* <table style={{ border: '3px solid purple' }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">user_id</th>
            <th scope="col">group</th>
            <th scope="col">name</th>
            <th scope="col">email</th>
            <th scope="col">admission</th>
            <th scope="col">email잠김</th>
            <th scope="col">register date</th>
            <th scope="col">func</th>
          </tr>
        </thead>
        <tbody>
          {users && users.users.map((user, idx) => {
            return <tr>
              <th scope="row">{idx}</th>
              <td>{user.user_id}</td>
              <td>{user.Group.group_name}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.login_check ? "Verified" : "Locked"}</td>
              <td>{user.eml_lock ? "Locked" : "UnLocked"}</td>
              <td>{user.createdAt}</td>
              <td style={{ dispaly: 'flex', gap: '10px' }}>
                <span
                  style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                >delete</span>
                <span
                  style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                >modify</span>
                <span
                  style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                  onClick={() => handleShowUserInfo(user)}
                >show info</span>
                {!user.login_check ?
                  <span
                    style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                  >승인ㄱ</span>
                  :
                  <span
                    style={{ border: '1px solid', paddingLeft: '14px', paddingRight: '14px' }}
                  >승인됨</span>
                }
              </td>
            </tr>
          })}
        </tbody>
      </table> */}
      <br />
      <br />
      <br />
      <br />
      {/* <h1> 모델</h1>
      <hr />
      모델 리스트 보기 /api/model/list
      <table style={{ border: "3px solid purple" }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">model_id</th>
            <th scope="col">model_name</th>
            <th scope="col">model_version</th>
            <th scope="col">model_description</th>
            <th scope="col">model_method</th>
            <th scope="col">func</th>
          </tr>
        </thead>
        <tbody>
          {models &&
            models.map((model, idx) => {
              return (
                <tr>
                  <th scope="row">{idx}</th>
                  <td>{model.model_id}</td>
                  <td>{model.model_name}</td>
                  <td>{model.version}</td>
                  <td>{model.description}</td>
                  <td>{model.model_method}</td>
                  <td style={{ dispaly: "flex", gap: "10px" }}>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                      onClick={() => handleDeleteModel(model.model_id)}
                    >
                      delete
                    </span>
                    <span
                      style={{
                        border: "1px solid",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                      }}
                    >
                      modify
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <br />
      <br />
      모델 추가 /api/model/1
      <div>
        <button
          type="button"
          class="btn"
          data-toggle="modal"
          data-target="#addModelModal"
        >
          추가하기
        </button>

        
      </div>
      <div> 모델을 테스트 할 수 있는 채팅 UI 구성</div> */}
      <PGroupAdd modalName={"addPGroupModal"} />
      <ModelAdd modalName={"addModelModal"} />
    </div>
  );
}

const FileNode = ({ node, deleteFt }) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };


  const handleGetDirFile = (fgroupId, fgroupName) => {
    dispatch(adminFileListGetOnfid({ token, fgroupId }));
  };

  const onNameClick = ({ nodeData }) => {
    // defaultOnClick();
    console.log(nodeData)

    // const {
    //   path,
    //   name,
    //   checked,
    //   isOpen,
    // } = nodeData;

    handleGetDirFile(nodeData.fid, nodeData.name);
  };
  const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { deleteable } = nodeData;

    const handleClick = () => {
      defaultOnClick();
      deleteFt(nodeData.path[0]);
    };
  }

  return (
    <div className="ml-2">
      <div
        className="flex items-center cursor-pointer"

      >
        {node.children && node.children.length > 0 ? (
          <span
            className="mr-1 w-[20px]"
            onClick={toggleOpen}
          >
            {isOpen ? '📁' : '🗂️'}
          </span>
        ) : (
          <span
            className="mr-1 w-[20px]"
          >&nbsp;&nbsp;&nbsp;&nbsp;</span>
        )}
        <span
          className=""
          onClick={() => onNameClick({ nodeData: node })}
        >{node.name}</span>
      </div>

      {isOpen && node.children && (
        <div className="pl-2">
          {node.children.map((childNode) => (
            <FileNode key={childNode.fgroup_id} node={childNode} />
          ))}
        </div>
      )}
    </div>
  );
};

const FileTree = ({ data }) => {
  // console.log(data)
  return (
    <div className="p-2 bg-gray-100 rounded-md  max-h-[500px]  overflow-scroll overflow-x-hidden">
      <FileNode node={data} />
    </div>
  );
};



const BasicTree = (props) => {
  const data = props.data;
  const deleteFt = props.deleteFt;

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const onTreeStateChange = (state, event) => {
    // console.log("state")
    // console.log(state)
    switch (event.type) {
      case "renameNode":
        // console.log('rename');
        // console.log(event);
        return;
      case "deleteNode":
        // deleteFt(event.path[0])
        // console.log('delete');
        // console.log(event);
        return;
        // console.log(state.children[event.path[0]]._id)
        break;
      default:
        // console.log(state, event);
        break;
    }
  };

  const handleGetDirFile = (fgroupId, fgroupName) => {
    // selectDirName(fgroupName)
    dispatch(adminFileListGetOnfid({ token, fgroupId }));
  };

  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();

    const {
      // internal data
      path,
      name,
      checked,
      isOpen,
      // custom data
      // _id, ...whateverRest
    } = nodeData;

    // console.log("Cilck")
    // console.log(name)
    handleGetDirFile(nodeData.fid, nodeData.name);
  };
  const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { deleteable } = nodeData;
    // console.log("nodeData")
    // console.log(nodeData)

    const handleClick = () => {
      // doSthBad({ path, name, checked, isOpen, ...restData });
      // console.log("do sth")

      defaultOnClick();
      deleteFt(nodeData.path[0]);
    };

    // if this node is editable, render an EditIcon, otherwise render air
    return <FaTrashAlt onClick={handleClick} />; //deleteable ? deleteFt(nodeData.id) : null;
  };

  return (
    <FolderTree
      data={data}
      showCheckbox={false}
      onChange={onTreeStateChange}
      onNameClick={onNameClick}
      iconComponents={{ DeleteIcon }}
    />
  );
};

function ModelAdd(props) {
  const { modalName } = props.modalName;

  const [newModelName, setNewModelName] = useState("");
  const [newModelDesc, setNewModelDesc] = useState("");
  const [newModelVersion, setNewModelVersion] = useState("");
  const [newModelMethod, setNewModelMethod] = useState("");

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const handleAddModel = (modelName, description, modelMethod, version) => {
    // console.log({ token, modelName, modelDesc, modelMethod, modelVersion })
    dispatch(
      adminModelAdd({ token, modelName, description, modelMethod, version })
    ).then(() => {
      dispatch(adminModelListGet({ token }));
    });
    window.confirm("추가되었습니다.");
    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };

  return (
    <div
      class="modal fade"
      id="addModelModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              모델 추가
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            모델 추가 form
            <form action="">
              <label htmlFor="">model name</label>
              <input
                type="text"
                value={newModelName}
                onChange={(ev) => setNewModelName(ev.target.value)}
              />
              <br />
              <label htmlFor="">version</label>
              <input
                type="text"
                value={newModelVersion}
                onChange={(ev) => setNewModelVersion(ev.target.value)}
              />
              <br />
              <label htmlFor="">model method</label>
              <input
                type="text"
                value={newModelMethod}
                onChange={(ev) => setNewModelMethod(ev.target.value)}
              />
              <br />
              <label htmlFor="">description</label>
              <input
                type="text"
                value={newModelDesc}
                onChange={(ev) => setNewModelDesc(ev.target.value)}
              />
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() =>
                handleAddModel(
                  newModelName,
                  newModelDesc,
                  newModelMethod,
                  newModelVersion
                )
              }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function FGroupAdd(props) {
  const { modalName } = props.modalName;

  const [newFGroupName, setNewFGroupName] = useState("");

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const handleAddFGroup = (fgroupName) => {
    // console.log({ token, modelName, modelDesc, modelMethod, modelVersion })
    dispatch(adminFGroupAdd({ token, fgroupName })).then(() => {
      dispatch(adminFGroupListGet({ token }));
    });
    window.confirm("추가되었습니다.");
    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };

  return (
    <div
      class="modal fade"
      id="addFGroupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addFGroupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addFGroupModalLabel">
              파일 그룹 추가
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            파일 그룹 추가 form
            <form action="">
              <label htmlFor="">model name</label>
              <input
                type="text"
                value={newFGroupName}
                onChange={(ev) => setNewFGroupName(ev.target.value)}
              />
              <br />
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => handleAddFGroup(newFGroupName)}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PGroupAdd(props) {
  const { modalName } = props.modalName;

  const [newPGroupName, setNewPGroupName] = useState("");

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const handleAddPGroup = (pgroupName) => {
    dispatch(adminPGroupAdd({ token, pgroupName })).then(() => {
      dispatch(adminPGroupListGet({ token }));
    });
    window.confirm("추가되었습니다.");
    document.getElementsByClassName("modal fade show")[0].className =
      "modal fade";
    document.getElementsByClassName("modal-backdrop fade show")[0].className =
      "modal-backdrop fade";
  };

  return (
    <div
      class="modal fade"
      id="addPGroupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addPGroupModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addPGroupModalLabel">
              파일 그룹 추가
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            파일 그룹 추가 form
            <form action="">
              <label htmlFor="">파일그룹 이름</label>
              <input
                type="text"
                value={newPGroupName}
                onChange={(ev) => setNewPGroupName(ev.target.value)}
              />
              <br />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddPGroup(newPGroupName)}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


const FileListItem = ({ file, onSelect }) => {
  return (
    <div className="flex items-center justify-between p-2 border-b">
      <div className="flex items-center">
        <input
          type="checkbox"
          className="mr-2"
          onChange={(e) => onSelect(file, e.target.checked)}
        />
        <span>{file.original_nm}</span>
      </div>
    </div>
  );
};

const FileList = ({ files, onSelect }) => {
  return (
    <div className="bg-white rounded-md shadow-md p-4">
      {files.map((file) => (
        <FileListItem key={file.pdf_id} file={file} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default AdminChatbotPage;

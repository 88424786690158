import React from 'react'
// import './chatMain.css';
import ChatContainer from './ChatContainer';
import PDFViewer from '../pdfViewer/PdfViewer';

import {
  ChatModel,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import SideBarDoc from './SideBarDoc';
import { VoiceRecordModal } from './ChatInput';
import Header from '../dashboard/Header';


function ChatMain({isOpen, setIsOpen}) {
  

  return (
    <main id="chatMain" 
      // className={`chatMain relative h-full w-full flex-1 overflow-auto transition-width transition-all duration-300 ease-in-out ${!isOpen ? 'ml-[-260px]' : 'ml-0'}`}>
      className='flex-1 flex flex-col justify-center items-center bg-gray-100'>
      
        <ChatContainer isOpen={isOpen} setIsOpen={setIsOpen}/>
       {/* <Header /> */}
      {/* <PageTitle page='Dashboard'/>
      <Dashboard /> */}
      {/* <SideBarDoc /> */}
      
      
      
    </main>
  )
}

export default ChatMain

// // import React, { useEffect, useState } from 'react';
// // import { Document, Page } from 'react-pdf';
// // import { pdfjs } from 'react-pdf';
// // import 'react-pdf/dist/Page/TextLayer.css';
// // import 'react-pdf/dist/Page/AnnotationLayer.css';
// // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// //     'pdfjs-dist/build/pdf.worker.min.mjs',
// //     import.meta.url,
// // ).toString();

// // const PDFViewer = ({ url, page }) => {
// //     // const  = props
// //     const [numPages, setNumPages] = useState(null);
// //     const [pageNumber, setPageNumber] = useState(page+1);

// //     useEffect(()=>{
// //       setPageNumber(page+1);
// //     }, [page])

// //     const options = {
// //         standardFontDataUrl: '/standard_fonts/',
// //       };

// //     function onDocumentLoadSuccess({ numPages }) {
// //       setNumPages(numPages);
// //     }

// //     console.log("PDFViwer")
// //     console.log({ url, page, numPages, pageNumber })

// //     return (
// //       <div>
// //         <Document
// //           options={options}
// //           file={url}
// //           // file={'http://155.230.135.140:3000/test.pdf'}
// //           onLoadSuccess={onDocumentLoadSuccess}>
// //           <Page 
// //             pageNumber={pageNumber} 
// //             loading="Loading..." 
// //             />
// //         </Document>
// //         <p>
// //             이전 페이지 보기
// //           <span onClick={()=> pageNumber > 1 ? setPageNumber(pageNumber-1):null}>
// //           &lt;&lt;&lt;&lt;
// //           </span>
// //           <span>Page {pageNumber} of {numPages}</span>
// //              다음 페이지 보기
// //           <span onClick={()=> pageNumber < numPages ? setPageNumber(pageNumber+1):null}>
// //           &gt;&gt;&gt;&gt;
// //           </span>
// //         </p>
// //       </div>
// //     );
// //   }


// // export default PDFViewer

// import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/TextLayer.css';
// import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

// const PDFViewer = ({ url, page }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(page + 1);

//   useEffect(() => {
//     setPageNumber(page + 1);
//   }, [page]);

//   const options = {
//     standardFontDataUrl: '/standard_fonts/',
//   };

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const goToPrevPage = () => {
//     if (pageNumber > 1) {
//       setPageNumber(pageNumber - 1);
//     }
//   };

//   const goToNextPage = () => {
//     if (pageNumber < numPages) {
//       setPageNumber(pageNumber + 1);
//     }
//   };

//   return (
//     <div>
//       <Document
//         options={options}
//         file={url}
//         onLoadSuccess={onDocumentLoadSuccess}
//       >
//         <Page
//           pageNumber={pageNumber}
//           loading="Loading..."
//         />
//       </Document>
//       <div className="pagination-container">
//     <p>
//         <span onClick={goToPrevPage} style={{ cursor: pageNumber > 1 ? 'pointer' : 'default' }}>
//             &lt;&lt;&lt;&lt;
//         </span>
//         <span> Page {pageNumber} of {numPages} </span>
//         <span onClick={goToNextPage} style={{ cursor: pageNumber < numPages ? 'pointer' : 'default' }}>
//             &gt;&gt;&gt;&gt;
//         </span>
//     </p>
// </div>
//     </div>
//   );
// };

// export default PDFViewer;


// import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/TextLayer.css';
// import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();


// const PDFViewer = ({ url, page }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(page + 1);

//   useEffect(() => {
//     setPageNumber(page + 1);
//   }, [page]);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const goToPrevPage = () => {
//     if (pageNumber > 1) setPageNumber(pageNumber - 1);
//   };

//   const goToNextPage = () => {
//     if (pageNumber < numPages) setPageNumber(pageNumber + 1);
//   };

//   return (
//     <div>
//       <Document
//         file={url}
//         onLoadSuccess={onDocumentLoadSuccess}
//       >
//         <Page pageNumber={pageNumber} loading="Loading..." />
//       </Document>
//       <div className="pagination">
//         <span onClick={goToPrevPage} className={pageNumber > 1 ? 'active' : 'disabled'}>&lt;&lt;&lt;&lt;</span>
//         <span> Page {pageNumber} of {numPages} </span>
//         <span onClick={goToNextPage} className={pageNumber < numPages ? 'active' : 'disabled'}>&gt;&gt;&gt;&gt;</span>
//       </div>
//     </div>
//   );
// };

// export default PDFViewer;


import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
// import './PdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const PDFViewer = ({ url, page, isOpen }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(page );
  const [scale, setScale] = useState(1);
  const viewerRef = useRef(null); // PDF 뷰어 컨테이너 참조

  useEffect(() => {
    setPageNumber(page + 1);
  }, [page]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  // PDF 페이지 너비에 따라 스케일을 동적으로 조정하는 함수
  useEffect(() => {
    const handleResize = () => {
      if (viewerRef.current) {
        const containerWidth = viewerRef.current.offsetWidth;
        const defaultPageWidth = 600; // PDF의 기본 너비
        const newScale = containerWidth / defaultPageWidth;
        setScale(newScale > 1.5 ? 1.5 : newScale); // 기본 스케일보다 크게 하지 않음
        // console.log({containerWidth, defaultPageWidth, newScale})
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // 컴포넌트 초기 로드 시에도 실행
    
    return () => window.removeEventListener('resize', handleResize);
  }, [viewerRef,isOpen]);

  // const loadingTask = pdfjs.getDocument({
  //   url: url,
  //   cMapPacked: true,
  //   enableXfa: true,
  // });

  // console.log({url, page})

  return (
    <div className="pdf-viewer-container flex flex-col w-full h-full" ref={viewerRef}>
      <div className="min-h-[700px]">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
        >
          <Page pageNumber={pageNumber} scale={scale}  loading="Loading..." />
        </Document>
      </div>

      <div className="pagination-container  bg-gray-100 p-2 my-10 flex justify-center items-center">
        <button
          onClick={goToPrevPage}
          disabled={pageNumber <= 1}
          className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
        >
          &lt; Prev
        </button>
        <span className="pagination-info">
          Page {pageNumber} of {numPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={pageNumber >= numPages}
          className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
        >
          Next &gt;
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;

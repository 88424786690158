// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #3d8de3;
  --primary-color-dark: #0056b3;
  --bg-color-main: #ffffff;
  --bg-color-secondary: #f9f9f9;
  --text-color-primary: #2c2d3a;
  --text-color-secondary: #6c757d;
  --border-radius: 12px;
  --box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  --padding-default: 16px;
  --padding-small: 8px;
  --font-size-base: 16px;
  --font-size-small: 14px;
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --bgColor: #f6f9ff;
  

  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root{
  width: 100vw;
  height: 100vh;
}

.App{
  width: 100vw;
  height: 100vh;
}

body {
  font-family: 'Open sans', sans-serif;
  color: #444444;
  background: var(--bgColor);
  overflow-x: hidden;
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,wBAAwB;EACxB,6BAA6B;EAC7B,6BAA6B;EAC7B,+BAA+B;EAC/B,qBAAqB;EACrB,6CAA6C;EAC7C,uBAAuB;EACvB,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;EAIvB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;;;EAGlB;IACE,WAAW;EACb;EACA;IACE,YAAY;EACd;AAdF;;AAiBA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;;;;;;EAME,iCAAiC;AACnC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');\n\n:root {\n  --primary-color: #3d8de3;\n  --primary-color-dark: #0056b3;\n  --bg-color-main: #ffffff;\n  --bg-color-secondary: #f9f9f9;\n  --text-color-primary: #2c2d3a;\n  --text-color-secondary: #6c757d;\n  --border-radius: 12px;\n  --box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n  --padding-default: 16px;\n  --padding-small: 8px;\n  --font-size-base: 16px;\n  --font-size-small: 14px;\n}\n\n:root {\n  scroll-behavior: smooth;\n  --primary: #4154f1;\n  --second: #717ff5;\n  --bgColor: #f6f9ff;\n  \n\n  .w-full {\n    width: 100%;\n  }\n  .h-full {\n    height: 100%;\n  }\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n#root{\n  width: 100vw;\n  height: 100vh;\n}\n\n.App{\n  width: 100vw;\n  height: 100vh;\n}\n\nbody {\n  font-family: 'Open sans', sans-serif;\n  color: #444444;\n  background: var(--bgColor);\n  overflow-x: hidden;\n}\n\na {\n  color: var(--primary);\n  text-decoration: none;\n  cursor: pointer;\n}\n\na:hover {\n  color: var(--second);\n  text-decoration: none;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: 'Nunito', sans-serif;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

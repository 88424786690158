import React, { useEffect, useState } from "react";
import {
  adminFaqList,
  adminFaqAdd,
  selectDashboardTestFeedbacks,
  adminTestFeedbacks,
} from "../../../lib/features/admin/adminSlice";
import {
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import { selectDashboardFaqs } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

function AdminFaqPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const status = useAppSelector(selectStatus);
  const faqs = useAppSelector(selectDashboardFaqs);
  const testFeedbacks = useAppSelector(selectDashboardTestFeedbacks);
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqManual, setFaqManual] = useState("");
  const [faqPage, setFaqPage] = useState("");
  const [chatbotId, setChatbotId] = useState("");

  useEffect(() => {
    dispatch(adminFaqList({ token }));
    dispatch(adminTestFeedbacks({ token }));
  }, []);

  const handleAddFaq = () => {
    dispatch(
      adminFaqAdd({
        token,
        faq_question: faqQuestion,
        faq_manual: faqManual,
        faq_page: faqPage,
        chatbotId: chatbotId,
      })
    ).then(() => {
      // Refresh the list after adding a new FAQ
      setFaqQuestion("");
      setFaqManual("");
      setFaqPage("");
      setChatbotId("");
      dispatch(adminFaqList({ token }));
    });
  };
  console.log({testFeedbacks})

  return (
    <div className="mt-[60px]">
      <div className="contentPanel">
        <h3>Admin Faqs Logs</h3>
        <hr />
        {status === "loading" ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Question</th>
                  <th scope="col">Manual</th>
                  <th scope="col">Page</th>
                  <th scope="col">ChatbotId</th>
                </tr>
              </thead>
              <tbody>
                {faqs &&
                  faqs.map((faq) => (
                    <tr key={faq.faq_id}>
                      <td>{faq.faq_id}</td>
                      <td>{new Date(faq.created_at).toLocaleString()}</td>
                      <td>{faq.faq_question}</td>
                      <td>{faq.faq_manual}</td>
                      <td>{faq.faq_page}</td>
                      <td>{faq.chatbot_id}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
        
      </div>
      <br />


      <div className="contentPanel">
        <h3>Add New FAQ</h3>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddFaq();
          }}
        >
          <div>
            <label>Question: </label>
            <input
              type="text"
              value={faqQuestion}
              onChange={(e) => setFaqQuestion(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Manual: </label>
            <input
              type="text"
              value={faqManual}
              onChange={(e) => setFaqManual(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Page: </label>
            <input
              type="text"
              value={faqPage}
              onChange={(e) => setFaqPage(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Chatbot ID: </label>
            <input
              type="text"
              value={chatbotId}
              onChange={(e) => setChatbotId(e.target.value)}
              required
            />
          </div>
          <button type="submit">Add FAQ</button>
        </form>
        
      </div>
      <br />

      <div className="contentPanel">
        <h3>Feedbacks</h3>
        <hr />
        {status === "loading" ? (
        <p>Loading...</p>
      ) : (
        <>
          {testFeedbacks &&
            testFeedbacks.map((userFeedback) => (
              <div key={userFeedback.user_id}>
                <h3>User ID: {userFeedback.user_id}</h3>
                
                {userFeedback.feedback_groups.map((group) => (
                  <div key={group.room_id}>
                    <h4>Room ID: {group.room_id}</h4>
                    
                    <table>
                      <thead>
                        <tr>
                          <th>Feedback ID</th>
                          <th>Rating</th>
                          <th>Filename</th>
                          <th>Page Number</th>
                          <th>Rated Text</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {group.feedbacks.map((feedback) => (
                          <tr key={feedback.test_feedback_id}>
                            <td>{feedback.test_feedback_id}</td>
                            <td>{feedback.rating}</td>
                            <td>{feedback.filename}</td>
                            <td>{feedback.pagenum}</td>
                            <td>{feedback.rated_text || "N/A"}</td>
                            <td>
                              {new Date(feedback.created_at).toLocaleString()}
                            </td>
                            <td>
                              {new Date(feedback.updated_at).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
        </>
      )}
        
      </div>
      <br />

    </div>
  );
}

export default AdminFaqPage;

import {
  AdminGet,
  AdminGroupAdd,
  AdminGroupUpdate,
  AdminGroupDelete,
  AdminModelAdd,
  AdminModelUpdate,
  AdminModelDelete,
  AdminFileGetOnfid,
  AdminFileAdd,
  AdminFileDelete,
  AdminFGroupAdd,
  AdminFGroupUpdate,
  AdminFGroupDelete,
  AdminPGroupAdd,
  AdminPGroupUpdate,
  AdminPGroupDelete,
  AdminPGroupFileGet,
  AdminPGroupFileAdd,
  AdminChatbotAdd,
  AdminChatbotDelete,
  AdminUserEmailLockUpdate,
  AdminUserUpdate,
  AdminFaqRequest,
} from "./adminSlice";

const BE_HOST = process.env.REACT_APP_BE_HOST;
// console.log(BE_HOST);
// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
// const BASE_URL: string = "http://localhost:12345";

// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOjIsImp0aSI6Im1OdkhjZlJsRkwiLCJpYXQiOjE3MjA0MTQxOTYsImV4cCI6MTc1MTk1MDE5Nn0.m5MbZMy3jYB71ba3Trqb7nBqGGq4MCw9VQbFluQnXhE
// A mock function to mimic making an async request for data

// 유저 삭제
export const fetchAdminUserDel = async (
  adminUserUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/auth/del", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserUpdateData.token,
    },
    body: JSON.stringify({
      userId: adminUserUpdateData.userId,
    }),
  });

  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

// 유저 업데이트
export const fetchAdminUserUpdate = async (
  adminUserUpdateData: AdminUserUpdate
) => {
  const response = await fetch(BASE_URL + "/api/auth/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserUpdateData.token,
    },
    body: JSON.stringify({
      userId: adminUserUpdateData.userId,
      groupId: adminUserUpdateData.groupId,
    }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

// 이메일 체크 업데이트
export const fetchAdminEmailUpdate = async (
  adminUserEmailUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/admin/email/update/check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserEmailUpdateData.token,
    },
    body: JSON.stringify({ userId: adminUserEmailUpdateData.userId }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

//lock 업데이트
export const fetchAdminLockUpdate = async (
  adminUserEmailUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/admin/update/lock", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserEmailUpdateData.token,
    },
    body: JSON.stringify({ userId: adminUserEmailUpdateData.userId }),
  });

  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};
export const fetchAdminAdminFaqs = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/file/faqs", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

export const fetchAdminTestFeedbacks = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/chatbot/test/feedback", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

export const fetchAdminAdminFaqAdd = async (
  adminFaqRequestData: AdminFaqRequest
) => {
  const response = await fetch(BASE_URL + "/api/file/faq", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminFaqRequestData.token,
    },
    body: JSON.stringify(adminFaqRequestData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

export const fetchAdminApilogs = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/user/apilog", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

// export const fetchAdminAdminFeedbackTexts = async (adminGetData: AdminGet) => {
//   const response = await fetch(BASE_URL + "/api/admin/user/apilog", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: adminGetData.token,
//     },
//     // body: JSON.stringify(messageSendData),
//   });
// console.log(response.json())
// console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
//   const result: any = await response.json();

//   return result;
// };

export const fetchAdminAdminFeedbackLikes = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/user/feedback", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

export const fetchAdminEmailModeList = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/admin/user/eml", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};
export const fetchAdminUserAllGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/admin/user/all", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return result;
};

export const fetchAdminGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/user/group", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminGroupAdd = async (adminAddData: AdminGroupAdd) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(BASE_URL + "/api/user/group", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      group_name: adminAddData.groupName,
      group_grd: adminAddData.groupGrd,
      group_description: adminAddData.groupDesc,
    }),
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminGroupDelete = async (
  adminDeleteData: AdminGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/user/group/" + adminDeleteData.groupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminGroupUpdate = async (
  adminUpdateData: AdminGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/user/group/" + adminUpdateData.groupId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        group_name: adminUpdateData.groupName,
        group_grd: adminUpdateData.groupGrd,
        group_description: adminUpdateData.groupDesc,
      }),
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminModelListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/model", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminModelAdd = async (adminAddData: AdminModelAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/model", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      model_name: adminAddData.modelName,
      description: adminAddData.description,
      version: adminAddData.version,
      model_method: adminAddData.modelMethod,
    }),
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminModelDelete = async (
  adminDeleteData: AdminModelDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/model/" + adminDeleteData.modelId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminModelUpdate = async (
  adminUpdateData: AdminModelUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/model/" + adminUpdateData.modelId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        model_name: adminUpdateData.modelName,
        description: adminUpdateData.description,
        version: adminUpdateData.version,
        model_method: adminUpdateData.modelMethod,
      }),
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminFileListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/file", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();
  // console.log(result);

  return result;
};

export const fetchAdminFileListGetOnfid = async (
  adminGetData: AdminFileGetOnfid
) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(
    BASE_URL + "/api/file?fgroupId=" + adminGetData.fgroupId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminGetData.token,
      },
    }
  );
  const result: any = await response.json();
  // console.log(result);

  return result;
};

export const fetchAdminFileAdd = async (adminAddData: AdminFileAdd) => {
  // console.log("fetchAdminFileAdd");
  // console.log(adminAddData);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", adminAddData.token);
  const formdata = new FormData();
  adminAddData.files.map((file) => formdata.append("files", file));
  formdata.append("mode", "pdf");
  formdata.append("chatbot_model_id", "2");
  formdata.append("retriever_model_id", "1");
  // console.log(formdata);
  const response = await fetch(BASE_URL + "/api/file/upload", {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  });
  const result: any = await response.json();
  // console.log(result);

  return result;
};

export const fetchAdminFileDelete = async (
  adminDeleteData: AdminFileDelete
) => {
  const response = await fetch(
    BASE_URL + "/api/file/pdf/" + adminDeleteData.fileId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminFGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/fgroup", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminFGroupAdd = async (adminAddData: AdminFGroupAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/fgroup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      pgroup_name: adminAddData.fgroupName,
    }),
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminFGroupDelete = async (
  adminDeleteData: AdminFGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/fgroup?fgroupId=" + adminDeleteData.fgroupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminFGroupUpdate = async (
  adminUpdateData: AdminFGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/fgroup?fgroupId=" + adminUpdateData.fgroupId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        fgroup_name: adminUpdateData.fgroupName,
      }),
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/file/pgroup", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupAdd = async (adminAddData: AdminPGroupAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/file/pgroup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      pgroup_name: adminAddData.pgroupName,
    }),
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupDelete = async (
  adminDeleteData: AdminPGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/file/pgroup?pgroupId=" + adminDeleteData.pgroupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupUpdate = async (
  adminUpdateData: AdminPGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/file/pgroup?pgroupId=" + adminUpdateData.pgroupId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        pgroup_name: adminUpdateData.pgroupName,
      }),
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupFileListGet = async (
  adminGetData: AdminPGroupFileGet
) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(
    BASE_URL + "/api/file/pgroup/id?pgroupId=" + adminGetData.pgroup_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminGetData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminPGroupFileAdd = async (
  adminAddData: AdminPGroupFileAdd
) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(
    BASE_URL + "/api/file/pgroup/id?pgroupId=" + adminAddData.pgroup_id,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminAddData.token,
      },
      body: JSON.stringify({
        append_files: adminAddData.fids,
      }),
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminChatbotListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/chatbot", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminChatbotAdd = async (adminAddData: AdminChatbotAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/chatbot", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      pgroup_id: adminAddData.pgroupId,
      model_id: adminAddData.modelId,
    }),
  });
  const result: any = await response.json();

  return result;
};

export const fetchAdminChatbotDelete = async (
  adminDeleteData: AdminChatbotDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/chatbot?chatbotId=" + adminDeleteData.chatbotId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchAdminRoomListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/chatbot/room", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

import React, { useEffect } from "react";
import {
  adminApilogs,
  adminFeedbackLikes,
} from "../../../lib/features/admin/adminSlice";
import {
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import {
  selectDashboardApiLog,
  selectDashboardFeedbackLike,
} from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

function AdminLogPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const status = useAppSelector(selectStatus);
  const apiLogs = useAppSelector(selectDashboardApiLog);
  const feedbackLikes = useAppSelector(selectDashboardFeedbackLike);
  const parseDateString = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(8, 10);
    const minutes = dateString.substring(10, 12);
    const seconds = dateString.substring(12, 14);
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  };
  useEffect(() => {
    dispatch(adminApilogs({ token }));
    dispatch(adminFeedbackLikes({ token }));
  }, []);

  const strnig2Datetime = (s) => {
    const year = s.substring(0, 4);
    const month = s.substring(5, 7);
    const day = s.substring(8, 10);
    const hour = s.substring(11, 13);
    const min = s.substring(14, 16);
    const sec = s.substring(17, 19);

    return `${year}/${month}/${day} ${hour}:${min} `;
  }

  return (

    <div className="mt-[60px]">
      <div className="contentPanel">
        <h3>Admin API Logs</h3>
        <hr />
        {status === "loading" ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Method</th>
                  <th scope="col">URL</th>
                  <th scope="col">Status Code</th>
                  <th scope="col">Response Message</th>
                </tr>
              </thead>
              <tbody>
              {apiLogs &&
                apiLogs.map((log) => (
                  <tr key={log.api_log_id}>
                    <td>{log.api_log_id}</td>
                    <td>{strnig2Datetime(log.created_at)}</td>
                    <td>{log.request_method}</td>
                    <td>{log.request_url}</td>
                    <td>{log.response_status_code}</td>
                    <td>{log.response_message}</td>
                  </tr>
                ))}
            </tbody>
            </table>
          </>
        )}
        
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <br />
      <div className="contentPanel">
        <h3>User Feedback Likes</h3>
        <hr />
        {status === "loading" ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
              <tr>
                <th>Message ID</th>
                <th>Message Type</th>
                <th>Message Text</th>
                <th>Is Chatbot</th>
                <th>Room ID</th>
                <th>User ID</th>
                <th>Feedback ID</th>
                <th>Is Like</th>
                <th>Is Dislike</th>
                <th>Feedback Text</th>
                <th>Message Created At</th>
                <th>Message Updated At</th>
                <th>Feedback Created At</th>
                <th>Feedback Updated At</th>
              </tr>
              </thead>
              <tbody>
              {feedbackLikes &&
                feedbackLikes.map((feedbackLike) => (
                  <tr key={feedbackLike.feedback.feedback_id}>
                    <td>{feedbackLike.message_id}</td>
                    <td>{feedbackLike.message_type}</td>
                    <td>{feedbackLike.message_text || "N/A"}</td>
                    <td>{feedbackLike.is_chatbot ? "Yes" : "No"}</td>
                    <td>{feedbackLike.room_id}</td>
                    <td>{feedbackLike.user_id}</td>
                    <td>{feedbackLike.feedback.feedback_id}</td>
                    <td>{feedbackLike.feedback.is_like ? "Yes" : "No"}</td>
                    <td>{feedbackLike.feedback.is_dislike ? "Yes" : "No"}</td>
                    <td>{feedbackLike.feedback.feedback_text || "N/A"}</td>
                    <td>
                      {parseDateString(
                        feedbackLike.created_at
                      ).toLocaleString()}
                    </td>
                    <td>
                      {parseDateString(
                        feedbackLike.updated_at
                      ).toLocaleString()}
                    </td>
                    <td>
                      {parseDateString(
                        feedbackLike.feedback.created_at
                      ).toLocaleString()}
                    </td>
                    <td>
                      {parseDateString(
                        feedbackLike.feedback.updated_at
                      ).toLocaleString()}
                    </td>
                  </tr>
                ))}
            </tbody>
            </table>
          </>
        )}
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>  
    </div>
  );
}

export default AdminLogPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../../lib/features/auth/authSlice";
import { useAppDispatch } from "../../lib/hooks";

function FindPW(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onButtonClick = () => {
    setEmailError("");

    if ("" === email) {
      setEmailError("Please enter your email");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    dispatch(authLogin({ useremail: email, password: password }));
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-4xl font-bold mb-8">Find your Password</div>

      <div className="w-full max-w-md">
      <div className="inputContainer mb-6 w-full max-w-md">
      <label htmlFor="inputEmail1" className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
          <input
            type="email"
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label className="text-red-600 text-sm">{emailError}</label>
        </div>

        <div className="mt-6">
          <button
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-all duration-200"
            onClick={onButtonClick}
          >
            Send email
          </button>
        </div>
      </div>

      <div className="linkContainer w-full max-w-md flex justify-between mt-6 text-blue-500 underline cursor-pointer text-sm">
        <p className="mb-2 cursor-pointer hover:underline" onClick={() => props.mvPage("signin")}>
          Already have an account?
        </p>
        <p className="cursor-pointer hover:underline" onClick={() => props.mvPage("signup")}>
        Create account?
        </p>
      </div>
    </div>
  );
}

export default FindPW;

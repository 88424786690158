import React from 'react'

function AdminModelPage() {
  return (
    <div>
      adminModelPage
    </div>
  )
}

export default AdminModelPage

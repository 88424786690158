import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import { StoreProvider } from './StoreProvider.tsx'
import reportWebVitals from './reportWebVitals';

// const createStoreWithMiddleware = applyMiddleware(
//   promiseMiddleware,
// )(createStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider>
      
      <App />
    </StoreProvider>
  </React.StrictMode>
);

reportWebVitals();

import React from 'react'
import {
setDocViewer,

selectDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import './spinner.css';

function MessageWait(props) {
    const {type, isBot, text, sendTime} = props.model
    const dispatch = useAppDispatch();

    const handleClickDocView = (url, page) => {
        dispatch(setDocViewer({
            docUrl: url,
            docPage: page
        }));
    }

    return (
    <>
    {isBot?
        <div className="massageBox-bot">
            <Spinner />
            {/* <div className="sendTime">{sendTime}</div> */}
        </div>
    :
        <div className="massageBox-me">
            <div className="messageText">
                {text}
            </div>
            <div className="sendTime">{sendTime}</div>
        </div>
    }   
    </>
  )
}

function Spinner() {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    );
}

export default MessageWait

import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/js/dist/util";
import "bootstrap";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ChatPage from "./pages/ChatPage";
import Dashboard from "./pages/Dashboard";
import EmailSendPage from "./pages/EmailSendPage";
import ServicePage from "./pages/ServicePage";
import {
  userMe,
  selectToken,
  selectUser,
  selectIsAuthenticated,
  setToken,
} from "./lib/features/auth/authSlice";
import MenuPage from "./pages/MenuPage";

function App() {
  // state 관리 여기서 하기
  // 필요 state 들 정리
  // 일단 chating page.
  // chat model page 관리 필요
  // side menu 관리 필요
  //

  // 다 필요없고 리덕스 고고
  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const user = useSelector(selectUser);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken && !token && !user) {
      dispatch(setToken(storedToken));
      dispatch(userMe(storedToken));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token && !isAuthenticated && !user) {
      dispatch(userMe(token));
    }
  }, [dispatch, token, isAuthenticated]);

  return (
    <div className="App relative flex h-full w-full overflow-hidden transition-colors">
      <BrowserRouter>
        <Routes path="/" element={<Home />}>
          <Route
            index
            element={<Home />}
          />
          <Route
            path="dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="chat"
            element={isAuthenticated ? <ChatPage /> : <Navigate to="/" />}
          />
          <Route
            path="menu"
            element={isAuthenticated ? <MenuPage /> : <Navigate to="/" />}
          />
          <Route
            path="service"
            element={isAuthenticated ? <ServicePage /> : <Navigate to="/" />}
          />
          <Route
            path="service/:roomId"
            element={isAuthenticated ? <ServicePage /> : <Navigate to="/" />}
          />
          <Route
            path="email-send"
            element={isAuthenticated ? <EmailSendPage /> : <Navigate to="/" />}
          />
          <Route
            path="email-send/:roomId"
            element={isAuthenticated ? <EmailSendPage /> : <Navigate to="/" />}
          />
          {/* <Route path="/" element={<Home />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;

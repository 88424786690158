import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoImgage from "../images/snsys_logo.png";
import { selectUser } from "../lib/features/auth/authSlice";
import { useAppSelector } from "../lib/hooks";
import Login from "../components/login/Login";
import Register from "../components/login/Register";
import FindPW from "../components/login/FindPW";
import backgroundImage from "../images/login_bg.jpg";

function Home() {
  const user = useAppSelector(selectUser);
  const [page, setPage] = useState("signin"); //'signin', 'signup', 'forgetpw'

  const navigate = useNavigate();

  useEffect(() => {}, [user]);

  const handleMovePage = () => {
    navigate("/chat");
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center h-screen transition-all duration-300 w-full">
      <div
        className="flex flex-col w-3/5 xs:w-full h-full bg-cover bg-center relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img
          className="absolute top-6 left-6 w-36 aspect-[3.57] object-auto"
          src={logoImgage}
          alt="logo"
        />
        <div className="flex flex-col flex-grow items-center text-center text-white font-bold p-10 bg-black/30">
          <div className="text-6xl font-semibold leading-snug tracking-wider mb-8  mt-40">
            Technology
            <br />
            Connect with You
          </div>
          <div className="text-2xl mt-4">
            에스엔시스는 오늘의 기술을 더 나은 당신의 내일로 이어갑니다.
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-2/5 xs:w-full h-full p-6">
        {user ? (
          handleMovePage()
        ) : page === "signin" ? (
          <Login mvPage={setPage} />
        ) : page === "signup" ? (
          <Register mvPage={setPage} />
        ) : (
          <FindPW mvPage={setPage} />
        )}
      </div>
    </div>
  );
}

export default Home;

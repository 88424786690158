import React from 'react'

function MessageSelection(props) {
    const {type, isBot, text, sendTime} = props.model
    return (
    <>
    {isBot?
        <div className="massageBox-bot">
            <div className="messageText">
                {text}
                selection
            </div>
            <div className="sendTime">{sendTime}</div>
        </div>
    :
        <div className="massageBox-me">
            <div className="messageText">
                {text}selection
            </div>
            <div className="sendTime">{sendTime}</div>
        </div>
    }   
    </>
  )
}

export default MessageSelection

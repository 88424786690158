import React from 'react'

function MessageVideo(props) {
    const {type, isBot, videoUrl, sendTime} = props.model
    return (
    <>
    {isBot?
        <div className="massageBox-bot">
            <video className="messageVideo" controls="controls">
                <source src="/video/video_sample.mp4" type="video/mp4" />
            </video>
            <div className="sendTime">{sendTime}</div>
        </div>
    :
        <div className="massageBox-me">
            <video className="messageVideo" controls="controls">
                <source src="/video/video_sample.mp4" type="video/mp4" />
            </video>
            <div className="sendTime">{sendTime}</div>
        </div>
    }   
    </>
  )
}

export default MessageVideo

// import React from 'react';
// import './sideBarDoc.css';
// // import navModel from '../../data/navItem'
// import {
//   setDocViewer,

//   selectDocViewer,
// } from "../../lib/features/chatMain/chatMainSlice";
// import { useAppDispatch, useAppSelector } from "../../lib/hooks";
// import PDFViewer from '../pdfViewer/PdfViewer';


// function SideBarDoc() {
//   const docViewer = useAppSelector(selectDocViewer);

//   console.log(docViewer)
//   return (
//     <aside id="sidebar-doc" className="sidebar-doc">
//         <ul className="sidebar-doc-panel" id="side-doc-panel">
//             <PDFViewer url={docViewer.docUrl} page={docViewer.docPage}/>
//         </ul>
//     </aside>
//   )
// }

// export default SideBarDoc


// import React from 'react';
// // import './sideBarDoc.css';
// import { useAppSelector } from "../../lib/hooks";
// import PDFViewer from '../pdfViewer/PdfViewer';

// function SideBarDoc() {
//   const docViewer = useAppSelector(state => state.chatMain.docViewer);

//   return (
//     <aside className="sidebar-doc">
//       <PDFViewer url={docViewer?.docUrl} page={docViewer?.docPage} />
//     </aside>
//   );
// }

// export default SideBarDoc;
import React, { useEffect, useState } from 'react';
import { useAppSelector } from "../../lib/hooks";
import PDFViewer from '../pdfViewer/PdfViewer';

// const SideBarDoc = ({ isOpen }) => {
const SideBarDoc = () => {
  const [isOpen, setIsOpen] = useState(false)
  const docViewer = useAppSelector(state => state.chatMain.docViewer);

  useEffect(()=>{
    setIsOpen(docViewer.isOn);
  }, [docViewer])

  const handleClickToggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div
      className={`flex transition-[max-width] duration-300 ease-in-out bg-gray-200 overflow-hidden ${isOpen ? "max-w-700px" : "max-w-1"
        }`}
      style={{ width: isOpen ? "700px" : "1px" }}
    >
      {isOpen ? (
        <div className="self-center relative w-10 h-[300px]  border border-gray-500 ">
          <button
            onClick={() => handleClickToggle()}
            className="w-10 h-[300px] bg-gray-100 text-gray-600 hover:bg-gray-200 p-2 rounded absolute left-0.5 z-20"
          >
            &gt;&gt;
          </button>
        </div>
      ) : (
        <div className="self-center w-10 h-[300px] border bg-gray-500  z-20">
          <button
            onClick={() => handleClickToggle()}
            className="w-10 h-[300px] bg-gray-300 text-gray-600 hover:bg-gray-200 p-2 rounded absolute right-0.5"
          >
            &lt;&lt;
          </button>
        </div>
      )}

      <div
        className={`flex justify-center items-center p-4 w-full h-full ${isOpen ? "block" : "hidden"
          }`}
      >
        <div className="w-full">
          <h1 className="text-xl text-center mb-4">PDF Viewer</h1>
          <PDFViewer url={docViewer?.docUrl} page={docViewer?.docPage} isOpen={isOpen} />
        </div>
      </div>
    </div>
  );
};

export default SideBarDoc;

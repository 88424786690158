import {
  roomCreate,
  roomGet,
  messageGet,
  messageCreate,
  messageCreateFile,
  messageIsLike,
  messageFeedbackText,
  keywordSearchList,
} from "./chatMainSlice";

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BE_HOST = process.env.REACT_APP_BE_HOST;

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
// console.log(BASE_URL)
// const BASE_URL: string = BE_HOST ? BE_HOST : "http://localhost:8806";
// const BASE_URL: string = "http://localhost:12345";

// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOjIsImp0aSI6Im1OdkhjZlJsRkwiLCJpYXQiOjE3MjA0MTQxOTYsImV4cCI6MTc1MTk1MDE5Nn0.m5MbZMy3jYB71ba3Trqb7nBqGGq4MCw9VQbFluQnXhE
// A mock function to mimic making an async request for data
export const fetchMessageIsLike = async (messageIsLikeData: messageIsLike) => {
  const response = await fetch(BASE_URL + `/api/chatbot/test/feedback/like`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: messageIsLikeData.token,
    },
    body: JSON.stringify(messageIsLikeData),
  });
  const result: any = await response.json();
  // console.log(result);
  return result;
};

export const fetchMessageFeedbackText = async (
  messageFeedbackTextData: messageFeedbackText
) => {
  const response = await fetch(BASE_URL + `/api/chatbot/feedback/text`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: messageFeedbackTextData.token,
    },
    body: JSON.stringify(messageFeedbackTextData),
  });
  const result: any = await response.json();
  // console.log(result);
  return result;
};

export const fetchChatMessageFile = async (
  messageSendData: messageCreateFile
) => {
  // console.log(messageSendData.formData);
  const response = await fetch(
    BASE_URL + `/api/chatbot/file?room_id=${messageSendData.room_id}`,
    {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: messageSendData.token,
      },
      body: messageSendData.formData,
    }
  );
  const result: any = await response.json();
  // console.log(result);
  return result;
};

export const fetchChatMessageSend = async (messageSendData: messageCreate) => {
  const response = await fetch(BASE_URL + "/api/chatbot/message", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: messageSendData.token,
    },
    body: JSON.stringify(messageSendData),
  });
  const result: any = await response.json();

  return result;
};
export const fetchkeywordSearchList = async (
  keywordSearchListData: keywordSearchList
) => {
  const response = await fetch(
    BASE_URL +
      `/api/chatbot/keyword/search?search=${keywordSearchListData.search}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: keywordSearchListData.token,
      },
    }
  );
  const result: any = await response.json();
  console.log(result);
  return result;
};
export const fetchChatMessageGet = async (messageGetData: messageGet) => {
  const response = await fetch(
    BASE_URL + "/api/chatbot/message?room_id=" + messageGetData.room_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: messageGetData.token,
      },
    }
  );
  const result: any = await response.json();

  return result;
};

export const fetchChatRoomCreate = async (roomCreateData: roomCreate) => {
  const response = await fetch(BASE_URL + "/api/chatbot/room", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: roomCreateData.token,
    },
    body: JSON.stringify(roomCreateData),
  });
  const result: any = await response.json();

  return result;
};

export const fetchChatRoomGet = async (roomGetData: roomGet) => {
  const response = await fetch(BASE_URL + "/api/chatbot/room", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: roomGetData.token,
    },
  });
  const result: any = await response.json();

  return result;
};

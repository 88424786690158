// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagetitle {
    margin-bottom: 10px;
}

.pagetitle h1{
    font-size: 24;
    margin-bottom: 0;
    font-weight: 600;
    color: #012970;
}

`, "",{"version":3,"sources":["webpack://./src/components/dashboard/pageTitle.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".pagetitle {\n    margin-bottom: 10px;\n}\n\n.pagetitle h1{\n    font-size: 24;\n    margin-bottom: 0;\n    font-weight: 600;\n    color: #012970;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

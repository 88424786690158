// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-container {
    position: relative;
    display: inline-block;
  }
  
  .popover-trigger {
    cursor: pointer;
  }
  
  .popover-content {
    position: fixed;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 300px;
    max-width: 800px;
  }
  
  .popover-footer {
    margin-top: 5px;
    text-align: right;
  }
  
  .view-document-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  
  .view-document-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/main/popover.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yCAAyC;IACzC,aAAa;IACb,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".popover-container {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .popover-trigger {\n    cursor: pointer;\n  }\n  \n  .popover-content {\n    position: fixed;\n    background-color: white;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 10px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    min-width: 300px;\n    max-width: 800px;\n  }\n  \n  .popover-footer {\n    margin-top: 5px;\n    text-align: right;\n  }\n  \n  .view-document-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 8px 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    width: 100%;\n    text-align: center;\n  }\n  \n  .view-document-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

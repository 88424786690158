import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
import { 
  fetchLogin, 
  fetchLogout, 
  fetchRegister, 
  fetchUser, 
  fetchUserLangChange 
} from "./authAPI";
import internal from "stream";
import { Token } from "typescript";

// 모델 정의
export interface Group {
  group_id: number;
  group_name: string;
  group_grd: string;
  group_dscription: string;
  createAt: string;
  updateAt: string;
}
export interface User {
  user_id: number;
  username: string;
  email: string;

  eml_check: boolean;
  eml_lock: boolean;
  lock_cms: boolean;
  login_check: boolean;
  del_check: boolean;
  user_lang: string;

  userLogs: string[];
  group: Group;

  createdAt: string;
  updatedAt: string;
}

export interface authState {
  isAuthenticated: boolean;
  status: "loading" | "failed" | "idle";
  token: string | null;
  user: User | null;
  errorMsg: string | null;
}

const initialState: authState = {
  isAuthenticated: false,
  status: "idle",
  errorMsg: "",
  token: null,
  user: null,
};

export interface authLogin {
  useremail: string;
  password: string;
}
export interface authRegister {
  useremail: string;
  username: string;
  password: string;
  lang: string;
}

export interface UserLangChangeData{
  token: string;
  lang: string;
}
// localStorage.setItem('token', token);
// localStorage.getItem('token');

export const authSlice = createAppSlice({
  name: "authentication",
  initialState,
  reducers: (create) => ({
    userMe: create.asyncThunk(
      async (token: string) => {
        const response = await fetchUser(token);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          if (action.payload.user) {
            state.status = "idle";
            state.user = action.payload.user;
            state.isAuthenticated = true;
          } else {
            state.status = "failed";
            state.user = null;
            state.isAuthenticated = false;
            state.errorMsg = action.payload.detail;
          }
        },
        rejected: (state, action) => {
          // console.log(action);
          state.status = "failed";
          state.isAuthenticated = false;
          state.token = null;
          localStorage.removeItem("token");
        },
      }
    ),
    authLogin: create.asyncThunk(
      async (authData: authLogin) => {
        const response = await fetchLogin(authData);
        // console.log(response);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          if (action.payload.user) {
            state.status = "idle";
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            state.token && localStorage.setItem("token", state.token);
          } else {
            state.status = "failed";
            state.user = null;
            state.isAuthenticated = false;
            state.errorMsg = action.payload.detail;
          }
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    authLogout: create.asyncThunk(
      async (token: string) => {
        const response = await fetchLogout(token);
        // console.log(response);
        return response;
      },
      {
        pending: (state) => {
          // console.log("pending");
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.user = null;
          state.token = null;
          state.isAuthenticated = false;
          localStorage.removeItem("token");
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    setToken: create.asyncThunk(
      async (token: string) => {
        return token;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.token = action.payload;
          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),

    userRegister: create.asyncThunk(
      async (authData: authRegister) => {
        const response = await fetchRegister(authData);
        // console.log(response);
        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          // console.log("pending");
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          // console.log("fulfilled");
          // console.log(action.payload);
          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    userLangChange: create.asyncThunk(
      async (userData: UserLangChangeData) => {
        const response = await fetchUserLangChange(userData);
        // console.log(response);
        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          // console.log("pending");
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.user!.user_lang = action.payload.lang;
          // console.log("fulfilled");
          // console.log(action.payload);
          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
  }),

  selectors: {
    // selectIndex: (state) => state.index,
    selectUser: (state) => state.user,
    selectToken: (state) => state.token,
    selectStatus: (state) => state.status,
    selectErrorMsg: (state) => state.errorMsg,
    selectIsAuthenticated: (state) => state.isAuthenticated,
    // selectChatSide: (state) => state.chatSide,
    // selectChatMain: (state) => state.chatMain,
    // selectChatRoomId: (state) => state.chatMain.roomId,
  },
});

export const { 
  authLogin, 
  authLogout, 
  userRegister, 
  userMe, 
  userLangChange,
setToken } =  authSlice.actions;
export const {
  // selectIndex,
  selectUser,
  selectToken,
  selectStatus,
  selectIsAuthenticated,
  selectErrorMsg,
  // selectChatSide,
  // selectChatMain,
  // selectChatRoomId
} = authSlice.selectors;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .input-field {
    display: flex;
    height: 60px;
    bottom: 0;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    box-shadow: 0 -1px 5px rgba(249, 230, 230, 0.1);
    max-width: 48rem;
}

.textarea-warp {
    flex: 1;
    margin-right: 10px;
}

.textarea {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ddd;
    outline: none;
    resize: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-wrap {
    display: flex;
    align-items: center;
}

.btn-plus, .btn-mic, .btn-send {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    font-size: 20px;
}

.btn-plus:hover, .btn-mic:hover, .btn-send:hover {
    background-color: #0056b3;
}
*/
/* .modal-content {
    z-index: 1060;
} */ 
`, "",{"version":3,"sources":["webpack://./src/components/main/chatInput.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAiDC;AACD;;GAEG","sourcesContent":["/* .input-field {\n    display: flex;\n    height: 60px;\n    bottom: 0;\n    align-items: center;\n    padding: 10px;\n    background-color: #f9f9f9;\n    border-top: 1px solid #ddd;\n    box-shadow: 0 -1px 5px rgba(249, 230, 230, 0.1);\n    max-width: 48rem;\n}\n\n.textarea-warp {\n    flex: 1;\n    margin-right: 10px;\n}\n\n.textarea {\n    width: 100%;\n    padding: 10px;\n    border-radius: 20px;\n    border: 1px solid #ddd;\n    outline: none;\n    resize: none;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n\n.btn-wrap {\n    display: flex;\n    align-items: center;\n}\n\n.btn-plus, .btn-mic, .btn-send {\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    cursor: pointer;\n    margin-left: 10px;\n    background-color: #007bff;\n    color: white;\n    font-size: 20px;\n}\n\n.btn-plus:hover, .btn-mic:hover, .btn-send:hover {\n    background-color: #0056b3;\n}\n*/\n/* .modal-content {\n    z-index: 1060;\n} */ \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

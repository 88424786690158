import React from "react";
import MessageBasic from "./MessageBasic";
import MessageCarousel from "./MessageCarousel";
import MessageVideo from "./MessageVideo";
import MessageImage from "./MessageImage";
import MessageSelection from "./MessageSelection";
import MessageWait from "./MessageWait";

function Message(props) {
  const { type, isBot, text, sendTime } = props.model;

  switch (type) {
    case "basic":
      return <MessageBasic model={props.model} roomId={props.roomId} />;
    case "image":
      return <MessageImage model={props.model} roomId={props.roomId} />;
    case "video":
      return <MessageVideo model={props.model} roomId={props.roomId} />;
    case "carousel":
      return <MessageCarousel model={props.model} roomId={props.roomId} />;
    case "selection":
      return <MessageSelection model={props.model} roomId={props.roomId} />;
    case "waiting":
      return <MessageWait model={props.model} roomId={props.roomId} />;
    default:
      return null;
  }
}

export default Message;

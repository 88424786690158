import React from 'react';
import './nav.css';
import NavNotice from './NavNotice';
import NavAvatar from './NavAvatar';
import NavMessage from './NavMessage';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../lib/hooks";
import { sidebarSel } from "../../lib/features/admin/adminSlice";

function Nav() {
  const navigate = useNavigate();
  
  return (
    <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
            {/* <li onClick={()=>navigate('/chat')} style={{color:'gray-400'}}>Chatpage</li>
            <li onClick={()=>navigate('/dashboard')} style={{color:'gray-400'}}>admin</li> */}
            {/* <NavNotice /> */}
            {/* <NavMessage /> */}
            <NavAvatar />
        </ul>

    </nav>
  )
}

// function Nav({ item, index }) {
//   const dispatch = useAppDispatch();

//   const handleMenuClick = (index_1, index_2) => {
//     dispatch(sidebarSel([index_1, index_2]));
//   };

//   return (
//     <>
//       <li className="nav-heading">{item.label}</li>
//       {item.items && item.items.map((menu, sub_idx) => (
//         <li key={`${index}-${sub_idx}`} className="nav-item" onClick={() => handleMenuClick(index, sub_idx)}>
//           <a className="nav-link collapsed" href={menu.href || '#'}>
//             <i className={menu.icon}></i>
//             <span>{menu.label}</span>
//           </a>
//         </li>
//       ))}
//     </>
//   );
// }
export default Nav

import React, { useState } from 'react'
import {Carousel,ListGroup,Container} from "react-bootstrap";

function CarouselContainer() {
    //critical to set initial state to zero
    const [index, setIndex] = useState(0);
    const caroucelSize = 2;
    

    const handleSelect = (selectedIndex, e) => {
        //adjust index to 0 if greater than index of last slide or less than zero
        //slide indexes are zero-based so yourLasSlideIndex would be 1 I guess
        if (selectedIndex >= caroucelSize){
            setIndex(0);
        } else if (selectedIndex < 0){
            setIndex(caroucelSize-1);
        } else if (selectedIndex !== index) {
            setIndex(selectedIndex);
        }
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} >
            <Carousel.Item>
                <Container className="d-block w-75">
                    <ListGroup className="d-block w-100" alt="First slide">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                        <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                </Container>
{/* 
                <img
                    className="d-block w-100"
                    src="https://fastly.picsum.photos/id/272/1000/1000.jpg?hmac=B7B8wHhqS-EyTMKgNa8PZ8GnJ49JgIaFjfnDa_eE6E8"
                    alt="First slide"
                /> */}
            </Carousel.Item>
            <Carousel.Item>
                <Container className="d-block w-75">
                    <ListGroup className="d-block w-100" alt="First slide">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                        <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                </Container>
            </Carousel.Item>
        </Carousel>
    );
}

function MessageCarousel(props) {
    const {type, isBot, text, sendTime} = props.model
    return (
    <>
    {isBot?
        <div className="massageBox-bot">
            <CarouselContainer />
            {/* <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src="https://fastly.picsum.photos/id/272/1000/1000.jpg?hmac=B7B8wHhqS-EyTMKgNa8PZ8GnJ49JgIaFjfnDa_eE6E8" alt="First slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src="https://fastly.picsum.photos/id/423/1000/1000.jpg?hmac=6sYFYjKBl0yqAtqDKlgbbU-t3L6P67zgry3PwSiswDg" alt="Second slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src="https://fastly.picsum.photos/id/264/1000/1000.jpg?hmac=UEDvYuCgyex7Ws-LS6DOHjcGyYomV-VEO64OLmsEtv8" alt="Third slide" />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div> */}
            <div className="sendTime">{sendTime}</div>
        </div>
    :
        <div className="massageBox-me">
            <div className="messageText">
                {text}Carousel
            </div>
            <div className="sendTime">{sendTime}</div>
        </div>
    }   
    </>
  )
}

export default MessageCarousel

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .search-bar {
    min-width: 360px;
    padding: 0 20px;
}

@media (max-width: 1199px){
    .header .search-bar {
        position: fixed;
        top: 50px;
        left: 0px;
        right: 0px;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .header .search-bar-show{
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.header .search-form {
    width: 100%;
}

.header .search-form input {
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}

.header .search-form input:focus, 
.header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}

.header .search-form button i {
    color: #012970;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/searchBar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;QACf,SAAS;QACT,SAAS;QACT,UAAU;QACV,aAAa;QACb,kDAAkD;QAClD,iBAAiB;QACjB,aAAa;QACb,gBAAgB;QAChB,kBAAkB;QAClB,UAAU;IACd;;IAEA;QACI,SAAS;QACT,mBAAmB;QACnB,UAAU;IACd;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAS;IACT,eAAe;IACf,cAAc;IACd,uCAAuC;IACvC,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,6CAA6C;IAC7C,uCAAuC;AAC3C;;AAEA;IACI,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".header .search-bar {\n    min-width: 360px;\n    padding: 0 20px;\n}\n\n@media (max-width: 1199px){\n    .header .search-bar {\n        position: fixed;\n        top: 50px;\n        left: 0px;\n        right: 0px;\n        padding: 20px;\n        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);\n        background: white;\n        z-index: 9999;\n        transition: 0.3s;\n        visibility: hidden;\n        opacity: 0;\n    }\n\n    .header .search-bar-show{\n        top: 60px;\n        visibility: visible;\n        opacity: 1;\n    }\n}\n\n.header .search-form {\n    width: 100%;\n}\n\n.header .search-form input {\n    border: 0;\n    font-size: 14px;\n    color: #012970;\n    border: 1px solid rgba(1, 41, 112, 0.2);\n    padding: 7px 38px 7px 8px;\n    border-radius: 3px;\n    transition: 0.3s;\n    width: 100%;\n}\n\n.header .search-form input:focus, \n.header .search-form input:hover {\n    outline: none;\n    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);\n    border: 1px solid rgba(1, 41, 112, 0.3);\n}\n\n.header .search-form button {\n    border: 0;\n    padding: 0;\n    margin-left: -30px;\n    background: none;\n}\n\n.header .search-form button i {\n    color: #012970;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

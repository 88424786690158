import React from 'react'
// import './chatContainer.css'
import ChatInput from './ChatInput'
import ChatHeader from './ChatHeader'
import ChatContents from './ChatContents'

import {
  selectChatMain,
  selectChatMode,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import SideBarDoc from './SideBarDoc'
import Header from '../dashboard/Header';

function ChatContainer({isOpen, setIsOpen}) {
  const dispatch = useAppDispatch();
  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);

  return (
    <aside id="chatContainer" className="composer-parent flex h-full focus-visible:outline-0">
        <Header isOpen={isOpen} setIsOpen={setIsOpen}/>
        {chatMain.roomId ? 
        <div className=" composer-parent flex h-full w-[600px] pt-[60px] flex-col focus-visible:outline-0">
          <div className="flex-1 overflow-hidden">
            <div className="relative h-full">
              <ChatContents massages={chatMain.massages}/>
              
            </div>
            
          </div>
          <ChatInput />
           
        </div>
        :
        <div className="composer-parent flex h-full flex-col focus-visible:outline-0">
          <div className="flex-1 overflow-hidden">
            <div className=" h-full content-center ">
            <div class="flex flex-col items-center justify-center h-screen text-center font-sans">
              
              <p class="text-lg leading-relaxed">
                This is the service chat window.<br/><br/>
                You can view the previous chat history in the right sidebar.<br/><br/>
                You can toggle it open or close using the top toggle button.<br/>
                You can also start or expand a new chat.<br/>
                The right sidebar allows you to view PDFs.
            </p>
          </div>
            
            </div>
          </div>
          {/* <ChatInput /> */}
        </div>
        }

        {/* 챗 헤더: 정보가 있을까? 형식상 구현 */}
        {/* 메세지 타입: 일반 */}
        {/* 메세지 타입: 이미지 */}
        {/* 메세지 타입: 비디오 */}
        {/* 메세지 타입: 캐러셀 */}
        {/* 메세지 타입: 선택지 */}
        {/* 메세지 타입: 태그 클라우드 ? */}
        {/* 챗 인풋: 입력 받는 여러 요소 구현 */}
        
        
    </aside>
  )
}

export default ChatContainer

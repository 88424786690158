import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { combineSlices, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/auth/authSlice";
import { adminSlice } from "./features/admin/adminSlice";
import { chatMainSlice } from "./features/chatMain/chatMainSlice";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(adminSlice, chatMainSlice, authSlice);
// Infer the `RootState` type from the root reducer

// const reducers = combineReducers({
//   admin: adminSlice.reducer,
//   chatMain: chatMainSlice.reducer,
//   auth: authSlice.reducer,
// });

// const persistConfig = {
//   key: 'root',
//   //로컬스토리지를 사용할 것이기때문에 storage를 적어주었다
//   storage,
//   whitelist: ['admin', 'auth', 'chatMain']
// };
// const persistedReducer = persistReducer(persistConfig, reducers);

export const store = () => {
  return configureStore({
    reducer: rootReducer,
  });
};


export type AppStore = ReturnType<typeof store>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];
import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
// import { fetchNovelIndex, fetchNovelStory, fetchImagePrompt, fetchImageGen } from "./novelEditorAPI";
import {
  fetchChatMessageSend,
  fetchChatMessageGet,
  fetchChatRoomGet,
  fetchChatRoomCreate,
  fetchChatMessageFile,
  fetchMessageIsLike,
  fetchMessageFeedbackText,
  fetchkeywordSearchList,
} from "./chatMainAPI";

import chatNavModel from "../../../data/chatNavItem";

export interface FeedBackType {
  feedback_id: number;
  user_id: number;
  message_id: number;
  is_like: number;
  is_dislike: number;
  feedback_text?: string;
}

// 모델 정의

export interface ChatMessageEvidence {
  evidence_id: number;
  evidence_text: string;
  evidence_doc_id: number;
  pdf_id: number;
  pdf_page: number;
  pdf_url: string;
  retrieval_score: number;
}

export interface ChatMessage {
  _id?: number;
  type?: string; //"basic"|"image"|"video"|"carousel"|"selection"
  isBot: boolean;
  hasDoc?: boolean;
  hasImg?: boolean;
  // url: string;
  evidence?: ChatMessageEvidence[];
  // pageNum: number;
  sendTime?: string;
  feedback?: FeedBackType;
  text?: string | null;
  imageUrl?: string;
  videoUrl?: string;
}

export interface ChatMessageBasic extends ChatMessage {
  text: string;
}
export interface ChatMessageImage extends ChatMessage {
  imageUrl: string;
}
export interface ChatMessageVideo extends ChatMessage {
  videoUrl: string;
}
export interface ChatMessageCarousel extends ChatMessage {
  data: string; // 캐러셀 데이터 추가
}
export interface ChatMessageSelection extends ChatMessage {
  data: string; //선택지 데이터 구조 추가
}

export interface messageCreateFile {
  token: string;
  room_id: string;
  formData: FormData;
}

export interface messageIsLike {
  token: string;
  // message_id: number;
  // is_like: boolean;
  // is_dislike: boolean;
}

export interface messageFeedbackText {
  token: string;
  message_id: number;
  feedback_text: string;
}
export interface keywordSearchList {
  token: string;
  search: string;
}

export interface ChatMain {
  title: string;
  status: "loading" | "failed" | "idle";
  roomId: number | null;
  messages: ChatMessage[];
}
export interface Keyword {
  status: "loading" | "failed" | "idle";
  keywords: any[];
}
export interface ChatList {
  chatName: string;
  isActive: boolean;
  icon: string;
  to: string;
}
export interface ChatSideMenu {
  label: string;
  chatList: ChatList[];
}
export interface ChatSide {
  chatMenu: ChatSideMenu[];
}

export interface User {
  auth: null;
  name: string;
  company: string;
  email: string;
  isAdmission: boolean;
}
export interface ChatMessageSend {
  // roomId: number,
  // text: string,
  // type: string
  room_id: number;
  text: string;
  chatbot_model_id: number;
  retiever_model_id: number;
}

export interface DocViewer {
  isOn: boolean;
  docUrl: string;
  docPage: number;
}
export interface ChatMode {
  mode_1: string;
  mode_2: string;
}

export interface chatMainSliceState {
  index: number[];
  chatMode: ChatMode;
  chatMain: ChatMain;
  keywordList: Keyword;
  chatSide: ChatSide;
  docViewer: DocViewer;
}

export interface roomGet {
  token: string;
}
export interface roomCreate {
  token: string;
  room_name: string;
  room_type: string;
  chatbot_en_id: number;
  chatbot_kr_id: number;
  group_id: number;
  user_id: number;
}
export interface roomDelete {
  token: string;
}
export interface messageGet {
  token: string;
  room_id: number;
}
export interface messageCreate {
  token: string;
  room_id: string;
  message_text: string;
  message_type: string;
}
export interface messageDelete {
  token: string;
}

const initialState: chatMainSliceState = {
  index: [0, 0],
  chatMode: {
    mode_1: "",
    mode_2: "",
  },
  chatMain: {
    title: "",
    status: "idle",
    roomId: null,
    messages: [],
  },
  docViewer: {
    isOn: false,
    docUrl: "",
    docPage: -1,
  },
  chatSide: {
    chatMenu: [], //chatNavModel,
  },
  keywordList: {
    status: "idle",
    keywords: [],
  },
};

export const chatMainSlice = createAppSlice({
  name: "chatMain",
  initialState,
  reducers: (create) => ({
    keywordGetList: create.asyncThunk(
      async (keywordSearchListData: keywordSearchList) => {
        const response = await fetchkeywordSearchList(keywordSearchListData);

        return response;
      },
      {
        pending: (state) => {
          console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.keywordList.status = "idle";
          state.keywordList.keywords = action.payload;
          //응답 받아서 챗봇 응답까지 표시

          // console.log(state.chatMain.messages)
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    messageFeedbackText: create.asyncThunk(
      async (messageFeedbackTextData: messageFeedbackText) => {
        const response = await fetchMessageFeedbackText(
          messageFeedbackTextData
        );

        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          // console.log(state.chatMain.messages)
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    meesageLike: create.asyncThunk(
      async (messageLikeData: messageIsLike) => {
        const response = await fetchMessageIsLike(messageLikeData);

        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          // console.log(state.chatMain.messages);
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    chatMessageFile: create.asyncThunk(
      async (messageSendData: messageCreateFile) => {
        const response = await fetchChatMessageFile(messageSendData);

        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          // console.log(state.chatMain.messages)
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    addMessages: create.asyncThunk(
      async (messageGetData: any) => {
        return messageGetData;
        // 메시지 추가 로직
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";

          const message = action.payload;
          if (message.first) {
            state.chatMain.messages = [
              ...state.chatMain.messages,
              {
                type: "basic",
                isBot: true,
                text: message.message_text,
                hasDoc: message.message_pdf_name ? true : false,
                hasImg: false,
                // url: message.message_pdf_url,
                // evidence: [],
                // pageNum: message.message_pdf_page,
                sendTime: message.createdAt,
              },
            ];
          } else {
            state.chatMain.messages[state.chatMain.messages.length - 1] = {
              type: "basic",
              isBot: true,
              text: message.message_text,
              hasDoc: message.message_pdf_name ? true : false,
              evidence: [],
              hasImg: false,
              // url: message.message_pdf_url,
              // pageNum: message.message_pdf_page,
              sendTime: message.createdAt,
            };
          }
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatMessageGet: create.asyncThunk(
      async (messageGetData: messageGet) => {
        const response = await fetchChatMessageGet(messageGetData);
        // console.log(response);

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          const newMessage_bot = action.payload.messages.map((item: any) => {
            let type = "basic";
            let hasImg = false;
            if (item.message_type == "image_url") {
              type = "image";
              hasImg = true;
            }

            return {
              _id: item.message_id,
              type: type,
              isBot: item.is_chatbot,
              hasDoc: item.message_pdf_name ? true : false,
              hasImg: hasImg,
              // url: item.message_pdf_url,
              // pageNum: item.message_pdf_page,
              evidence: item.evidences.map(
                (evi: ChatMessageEvidence, evi_idx: number) => {
                  return {
                    evidence_id: evi.evidence_id,
                    evidence_text: evi.evidence_text,
                    evidence_doc_id: evi.evidence_doc_id,
                    pdf_id: evi.pdf_id,
                    pdf_page: evi.pdf_page,
                    pdf_url: evi.pdf_url,
                    retrieval_score: evi.retrieval_score,
                  };
                }
              ),
              text: item.message_text,
              imageUrl: item.message_base64,
              sendTime: item.created_at,
              feedback: item.feedback,
            };
          });
          state.chatMain.messages = [...newMessage_bot].sort(
            (a, b) => a._id - b._id
          );
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatMessageSend: create.asyncThunk(
      async (messageSendData: messageCreate) => {
        const response = await fetchChatMessageSend(messageSendData);

        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시
          const message = action.payload.messages;
          state.chatMain.messages = [
            ...state.chatMain.messages,
            {
              type: "basic",
              isBot: true,
              text: message.message_text,
              hasDoc: message.message_pdf_name ? true : false,
              hasImg: false,
              evidence: [],
              // url: message.message_pdf_url,
              // pageNum: message.message_pdf_page,
              sendTime: message.createdAt,
            },
          ];
          // console.log(state.chatMain.messages)
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    // chatMessageAdd: create.reducer((state, action: PayloadAction<string>) => {
    //   let today = new Date();
    //   //데이터 넣어서 로딩 돌리고,

    //   state.chatMain.messages =  [...state.chatMain.messages, {
    //     _id: state.chatMain.messages.length,
    //     type: "basic",
    //     hasDoc: false,
    //     hasImg: false,
    //     url: "",
    //     pageNum: 0,
    //     text: action.payload,
    //     isBot: false,
    //     sendTime: today.getDate().toString()+" "+today.getTime().toString()
    //   } as ChatMessageBasic,
    //   {
    //     _id: state.chatMain.messages.length,
    //     type: "waiting",
    //     hasDoc: false,
    //     hasImg: false,
    //     url: "",
    //     pageNum: 0,
    //     text: action.payload,
    //     isBot: true,
    //     sendTime: today.getDate().toString()+" "+today.getTime().toString()
    //   } as ChatMessage
    // ];
    // }),
    chatMessageAdd: create.asyncThunk(
      async (messsage: string) => {
        const response = messsage;

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          let today = new Date();
          state.chatMain.status = "idle";
          state.chatMain.messages = [
            ...state.chatMain.messages,
            {
              _id: state.chatMain.messages.length,
              type: "basic",
              hasDoc: false,
              hasImg: false,
              //url: "",
              //pageNum: 0,
              evidence: [],
              text: action.payload,
              isBot: false,
              sendTime:
                today.getDate().toString() + " " + today.getTime().toString(),
            } as ChatMessageBasic,
            {
              _id: state.chatMain.messages.length,
              type: "waiting",
              hasDoc: false,
              hasImg: false,
              // url: "",
              // pageNum: 0,
              evidence: [],
              text: action.payload,
              isBot: true,
              sendTime:
                today.getDate().toString() + " " + today.getTime().toString(),
            } as ChatMessage,
          ];
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    setChatRoomId: create.reducer((state, action: PayloadAction<number>) => {
      state.chatMain.roomId = action.payload;
    }),

    chatRoomGet: create.asyncThunk(
      async (roomGetData: messageGet) => {
        const response = await fetchChatRoomGet(roomGetData);
        // console.log(response);

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";

          const newSidebar = {
            label: "BWMS Troubleshooting",
            chatList: action.payload.map((item: any, idx: number) => {
              return {
                chatName: item.room_name,
                roomId: item.room_id,
                isActive: false,
                icon: "bi bi-menu-button-wide",
                to: "/",
              };
            }),
          };
          state.chatSide.chatMenu = [newSidebar];
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatRoomCreate: create.asyncThunk(
      async (roomSendData: roomCreate) => {
        const response = await fetchChatRoomCreate(roomSendData);
        // console.log(response);
        return response;
      },
      {
        pending: (state) => {
          // console.log(state);
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),

    sidebarSelRoom: create.reducer((state, action: PayloadAction<number[]>) => {
      state.index = action.payload;
      state.chatMain.title =
        state.chatSide.chatMenu[action.payload[0]].chatList[
          action.payload[1]
        ].chatName;
    }),

    setDocViewer: create.reducer((state, action: PayloadAction<DocViewer>) => {
      state.docViewer = action.payload;
    }),

    setToggleDocViewer: create.reducer((state) => {
      // console.log("setToggleDocViewer")
      state.docViewer.isOn = !state.docViewer.isOn;
    }),

    setChatMode: create.reducer((state, action: PayloadAction<ChatMode>) => {
      state.chatMode = action.payload;
    }),

    // chatMessageSend: create.reducer((state, action: PayloadAction<string>) => {
    //     let today = new Date();
    //     state.chatMain.messages = [...state.chatMain.messages,  {
    //       _id: state.chatMain.messages.length+1,
    //       type: "basic",
    //       isBot: false,
    //       sendTime: today.toLocaleString()
    //     }];
    //   }),
  }),

  selectors: {
    selectIndex: (state) => state.index,
    selectChatMode: (state) => state.chatMode,
    selectChatSide: (state) => state.chatSide,
    selectChatMain: (state) => state.chatMain,
    selectKeyword: (state) => state.keywordList,
    selectChatRoomId: (state) => state.chatMain.roomId,
    selectDocViewer: (state) => state.docViewer,
  },
});

export const {
  chatMessageGet,
  chatMessageSend,
  chatMessageAdd,
  chatRoomGet,
  chatRoomCreate,
  setChatRoomId,
  setDocViewer,
  addMessages,
  chatMessageFile,
  setToggleDocViewer,

  sidebarSelRoom,
  messageFeedbackText,
  meesageLike,
  setChatMode,
  keywordGetList,
} = chatMainSlice.actions;
export const {
  selectIndex,
  selectChatMode,
  selectKeyword,
  selectChatSide,
  selectChatMain,
  selectChatRoomId,
  selectDocViewer,
} = chatMainSlice.selectors;
